'use client';

import { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { ErrorBoundary } from '@sentry/nextjs';
import ErrorFallback from 'components/common/ErrorBoundary/ErrorFallback';
import useMainLayout from 'hooks/common/layout/useMainLayout';
import useErrorBoundary from 'hooks/useErrorBoundary';
import useMonitoring from 'hooks/useMonitoring';
import { SIDE_NAV, TOP_NAV } from 'utils/constants/layout';
import PreviewAppAlert from './PreviewAppAlert';
export const mainContainerStyles = (isReady: boolean, lgUp: boolean, sideNavMini: boolean) => ({
  position: 'relative',
  height: '100%',
  margin: '0',
  display: 'flex',
  flexDirection: 'column',
  ...(isReady && {
    width: lgUp ? `calc(100% - ${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px)` : '100%',
    marginLeft: lgUp ? `${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px` : 0,
    paddingTop: `${TOP_NAV.H_DESKTOP}px`
  })
});
interface ErrorFallbackProps {
  error: unknown;
  resetError: () => void;
}
interface MainLayoutContentProps {
  children: React.ReactNode;
}
const MainLayoutContent = ({
  children
}: MainLayoutContentProps) => {
  const {
    childrenBoundaryRef
  } = useErrorBoundary();
  const {
    sideNavMini,
    lgUp,
    isReady
  } = useMainLayout();
  const {
    newRelicError
  } = useMonitoring();
  const containerStyles = useMemo(() => mainContainerStyles(isReady, lgUp, sideNavMini), [isReady, lgUp, sideNavMini]);
  const errorBoundaryFallback = useCallback(({
    error,
    resetError
  }: ErrorFallbackProps) => {
    childrenBoundaryRef.current = resetError;
    newRelicError(error as Error);
    return <ErrorFallback resetError={resetError} />;
  }, [childrenBoundaryRef, newRelicError]);
  return <Box sx={containerStyles} data-sentry-element="Box" data-sentry-component="MainLayoutContent" data-sentry-source-file="MainLayoutContent.tsx">
      <PreviewAppAlert data-sentry-element="PreviewAppAlert" data-sentry-source-file="MainLayoutContent.tsx" />

      <ErrorBoundary fallback={errorBoundaryFallback} data-sentry-element="ErrorBoundary" data-sentry-source-file="MainLayoutContent.tsx">{children}</ErrorBoundary>
    </Box>;
};
export default MainLayoutContent;