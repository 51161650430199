'use client';

import { ProfitwellProvider as RealProfitwellProvider } from '@repo/profitwell';
import useChainContextStore from 'store/chainContext';
import type { ReactNode } from 'react';
const profitwellAppToken: string = process.env.NEXT_PUBLIC_PROFITWELL_TOKEN ?? '';
const ProfitwellProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const selectedOrganization = useChainContextStore(state => state.selectedOrganization);

  // If the selected organization has a Stripe customer ID, use the ID to track
  // the user engagement in Profitwell, this allows us to track engagement across
  // organizations that have active subscriptions.
  const stripeCustomerId = selectedOrganization?.stripe_customer_id ?? undefined;
  return <RealProfitwellProvider appToken={profitwellAppToken} userId={stripeCustomerId} data-sentry-element="RealProfitwellProvider" data-sentry-component="ProfitwellProvider" data-sentry-source-file="ProfitwellProvider.tsx">
      {children}
    </RealProfitwellProvider>;
};
export default ProfitwellProvider;