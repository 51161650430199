import { useMemo } from 'react';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import { CustomLink } from '@eventtemple/eventtemple-ui';
import type { CustomLinkProps } from '@eventtemple/eventtemple-ui';
import type { SUPPORTED_APP_CONTEXTS } from 'utils/constants/global';

/**
 * The props for the AppContextLink component.
 */
export type AppContextLinkProps = {
  /**
   * If the context should not be enabled on this redirect. Used for conditional
   * situations only where its more convenient to disable the context than to
   * create a new component.
   */
  disableContext?: boolean;
  /**
   * If the CustomLink component should be disabled and a regular Link component
   * should be used instead. This is useful for links that dont want any of our
   * custom link styles.
   */
  disableCustomLink?: boolean;
  /**
   * Optional context override. If provided, the context and contextId will be
   * overridden with the provided values & not be inherited from the current URL.
   * This is useful for context specific resources that may or may not be related
   * to the current context.
   * Ex. A booking link within the chain context should redirect to the booking
   * within the organization context, since that booking belongs to the organization,
   * not the chain.
   */
  contextOverride?: {
    context: (typeof SUPPORTED_APP_CONTEXTS)[keyof typeof SUPPORTED_APP_CONTEXTS];
    contextId: string;
  };
} & CustomLinkProps;

/**
 * The AppContextLink component is a wrapper around the CustomLink component that
 * automatically appends the context and contextId to the href. This is useful for
 * creating links within the app that need to be context aware.
 * @component
 */
const AppContextLink = ({
  href,
  children,
  contextOverride,
  disableCustomLink,
  disableContext,
  ...props
}: AppContextLinkProps) => {
  const {
    context,
    contextId
  } = useParams();
  const url = useMemo(() => {
    if (disableContext) return href;
    if (contextOverride) {
      return `/${contextOverride.context}/${contextOverride.contextId}${href}`;
    }
    return `/${context}/${contextId}${href}`;
  }, [context, contextId, href, contextOverride, disableContext]);
  const Component = disableCustomLink ? Link : CustomLink;
  return <Component href={url} {...props} data-sentry-element="Component" data-sentry-component="AppContextLink" data-sentry-source-file="AppContextLink.tsx">
      {children}
    </Component>;
};
export default AppContextLink;