import { useEffect } from 'react'

import usePermissions from 'hooks/permissions/usePermissions'
import useContextRouter from 'hooks/useContextRouter'
import Routes from 'types/enums/routes'

const useRouteRedirection = () => {
  const { push, pathnameWithoutContext } = useContextRouter()

  const { hasRoutePermissions } = usePermissions()

  useEffect(() => {
    if (hasRoutePermissions(pathnameWithoutContext as Routes)) return

    const isSettingsPage = pathnameWithoutContext.startsWith(Routes.SETTINGS)

    let redirect = Routes.SETTINGS

    if (hasRoutePermissions(Routes.BOOKINGS)) redirect = Routes.BOOKINGS

    if (isSettingsPage) {
      redirect = Routes.SETTINGS
      if (hasRoutePermissions(Routes.SETTINGS_ORGANIZATIONS)) {
        redirect = Routes.SETTINGS_ORGANIZATIONS
      } else if (hasRoutePermissions(Routes.SETTINGS_GENERAL_SETTINGS)) {
        redirect = Routes.SETTINGS_GENERAL_SETTINGS
      }
    }

    push(redirect)
  }, [hasRoutePermissions, pathnameWithoutContext, push])
}

export default useRouteRedirection
