import { useCallback, useMemo } from 'react';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { SUPPORTED_APP_CONTEXTS } from 'utils/constants/global';
import type { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
export type NavigateContext = {
  context: (typeof SUPPORTED_APP_CONTEXTS)[keyof typeof SUPPORTED_APP_CONTEXTS];
  contextId: string;
};

// Regex to match a UUID in the URL
const uuidRegex = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

// useContextRouter is a hook that provides a push and replace function that
// appends the context and contextId to the URL. This is used to support our
// dynamic routing based on the context and contextId in the URL and prevents
// everywhere navigation occurs from needing to know about the context and
// contextId.
const useContextRouter = () => {
  const router = useRouter();
  const pathname = usePathname();
  const {
    context,
    contextId
  } = useParams();
  const push = useCallback((url: string, contextConfig?: NavigateContext, options?: NavigateOptions) => {
    if (contextConfig) {
      const {
        context: newContext,
        contextId: newContextId
      } = contextConfig;
      return router.push(`/${newContext}/${newContextId}${url}`, options);
    }
    return router.push(`/${context}/${contextId}${url}`, options);
  }, [context, contextId, router]);
  const replace = useCallback((url: string, contextConfig?: NavigateContext, options?: NavigateOptions) => {
    if (contextConfig) {
      const {
        context: newContext,
        contextId: newContextId
      } = contextConfig;
      return router.replace(`/${newContext}/${newContextId}${url}`, options);
    }
    return router.replace(`/${context}/${contextId}${url}`, options);
  }, [context, contextId, router]);

  // URL without the context and contextId. Used for simple route checks and navigation.
  const pathnameWithoutContext = useMemo(() => {
    if (context === SUPPORTED_APP_CONTEXTS.ORG) {
      return pathname.replace(new RegExp(`/${SUPPORTED_APP_CONTEXTS.ORG}/${uuidRegex}`), '');
    }
    if (context === SUPPORTED_APP_CONTEXTS.CHAIN) {
      return pathname.replace(new RegExp(`/${SUPPORTED_APP_CONTEXTS.CHAIN}/${uuidRegex}`), '');
    }
    return '';
  }, [pathname, context]);
  return {
    ...router,
    context,
    contextId,
    push,
    replace,
    pathname,
    pathnameWithoutContext
  };
};
export default useContextRouter;