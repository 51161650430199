import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import FullPageLoader from 'components/FullPageLoader';
import useCurrentUser from 'hooks/currentUser/useCurrentUser';
import Routes from 'types/enums/routes';
import getLoginUrl from 'utils/getLoginUrl';
import type { ReactNode } from 'react';
type UserProviderProps = {
  children: ReactNode;
};

/**
 * The UserProvider is a wrapper component that loads the current user when the
 * application mounts. If there is no user logged in, it will redirect them to
 * the login page. This only needs to be invoked at the top level of the
 * application. Any components that need the current user data can use the
 * useCurrentUserStore.
 *
 * @component
 */
const UserProvider = ({
  children
}: UserProviderProps) => {
  const pathname = usePathname();
  const {
    isLoading,
    isError,
    error
  } = useCurrentUser();
  useEffect(() => {
    if (isError && pathname !== Routes.AUTH_LOGIN && !isLoading) {
      window.location.href = getLoginUrl(window.location.href);
    }
  }, [pathname, isError, isLoading]);

  // If the user is still loading or there was an error, show the full page loader,
  // when theres an error this mean authentication failed and the user is being
  if (isLoading || error) return <FullPageLoader />;
  return children;
};
export default UserProvider;