import { Menu } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import EtLogo from 'components/common/EtLogo';
import useResponsive from 'hooks/useResponsive';
import useCurrentUserStore from 'store/currentUser';
import { TOP_NAV } from 'utils/constants/layout';
import type { Theme } from '@mui/material';
const headerStyles = (mini: boolean, isETAdmin: boolean) => ({
  top: 0,
  position: 'sticky',
  minHeight: TOP_NAV.H_DESKTOP,
  zIndex: 10,
  display: 'flex',
  justifyContent: mini ? 'center' : 'space-between',
  alignItems: 'center',
  px: 2,
  backgroundColor: (theme: Theme) => isETAdmin ? theme.palette.error.dark : theme.palette.grey[900],
  // Since we are using scaleX to animate the side nav, we need to adjust the
  // position of the icon button a tiny bit to the left. This is because the
  // icon button is not scaled down.
  transform: mini ? 'translateX(-.5rem)' : 'none'
});
type SideNavHeaderProps = {
  toggleSideNavMini: () => void;
  onClose: () => void;
  mini: boolean;
  showSideNavToggle: boolean;
};
const SideNavHeader = ({
  toggleSideNavMini,
  onClose,
  mini,
  showSideNavToggle
}: SideNavHeaderProps) => {
  const currentUser = useCurrentUserStore();
  const lgUp = useResponsive('up', 'lg');
  const onButtonClick = lgUp ? toggleSideNavMini : onClose;
  const styles = {
    transform: mini ? 'scaleX(3)' : 'none'
  };
  const renderLogo = () => {
    if (mini) return null;
    return <EtLogo height={48} width={150} data-sentry-element="EtLogo" data-sentry-component="renderLogo" data-sentry-source-file="SideNavHeader.tsx" />;
  };
  const renderSideNavToggle = () => {
    if (!showSideNavToggle) return null;
    return <IconButton onClick={onButtonClick} sx={styles} data-sentry-element="IconButton" data-sentry-component="renderSideNavToggle" data-sentry-source-file="SideNavHeader.tsx">
        <Menu data-sentry-element="Menu" data-sentry-source-file="SideNavHeader.tsx" />
      </IconButton>;
  };
  const finalHeaderStyles = headerStyles(mini, Boolean(currentUser?.isETAdmin));
  return <Box id="side-nav-header" sx={finalHeaderStyles} data-sentry-element="Box" data-sentry-component="SideNavHeader" data-sentry-source-file="SideNavHeader.tsx">
      {renderLogo()}
      {renderSideNavToggle()}
    </Box>;
};
export default SideNavHeader;