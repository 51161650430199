import React, { useCallback } from 'react'

import Select from 'components/common/inputs/Select'

import { dateOptionLabels } from 'utils/dateOptionLabels'

import type { RelativeDatePickerProps } from 'components/common/date/RelativeDatePicker/RelativeDatePicker.types'

import type { RelativeDate } from 'types/dates'

const RelativeDatePicker = ({
  label,
  relativeDate,
  onRelativeDateChange,
  options = dateOptionLabels,
  SelectProps
}: RelativeDatePickerProps): React.JSX.Element => {
  const handleOnChange = useCallback(
    (event) => onRelativeDateChange?.(event.target.value as RelativeDate),
    [onRelativeDateChange]
  )
  const value = relativeDate ?? ''

  return (
    <Select
      {...SelectProps}
      values={options}
      label={label}
      onChange={handleOnChange}
      value={value}
    />
  )
}

export default RelativeDatePicker
