import type { MouseEvent } from 'react'
import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { ArrowDropDown } from '@mui/icons-material'

import { Box, Button, Menu, Tooltip } from '@mui/material'

import DropdownButtonItem from 'components/common/buttons/DropdownButton/DropdownButtonItem'

import type { DropdownButtonProps } from 'components/common/buttons/DropdownButton'

import type { MenuProps } from '@mui/material'

const anchorOrigin: MenuProps['anchorOrigin'] = { vertical: 'bottom', horizontal: 'right' }
const transformOrigin: MenuProps['transformOrigin'] = { vertical: 'top', horizontal: 'right' }
const defaultEndIcon = <ArrowDropDown />
const defaultVariant = 'contained'

const DropdownButton = <T extends string | number | string[] = string>({
  ref,
  MenuProps,
  options,
  children,
  customButton,
  closeOnSelect = true,
  endIcon = defaultEndIcon,
  onClose,
  onOpen,
  variant = defaultVariant,
  tooltipTitle,
  ...others
}: DropdownButtonProps<T>) => {
  const buttonRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = useCallback(
    (e?: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e?.stopPropagation()
      e?.preventDefault()

      if (onOpen) onOpen()

      setAnchorEl(e.currentTarget)
    },
    [onOpen]
  )

  const handleClose = useCallback(
    (e?: MouseEvent) => {
      e?.stopPropagation()
      e?.preventDefault()

      if (onClose) onClose()

      setAnchorEl(null)
    },
    [onClose]
  )

  const handleItemSelect = useCallback(() => {
    if (closeOnSelect) {
      setAnchorEl(null)
    }
  }, [closeOnSelect])

  useImperativeHandle(
    ref,
    () => ({
      close: handleClose,
      open: () => buttonRef.current?.click(),
      getOpenState: () => open
    }),
    [handleClose, open]
  )

  const menuSlotProps = useMemo(
    () => ({
      paper: {
        sx: {
          width: anchorEl && anchorEl.offsetWidth,
          minWidth: 'max-content'
        }
      }
    }),
    [anchorEl]
  )

  const renderButton = () => {
    if (customButton) {
      return (
        <Box
          ref={buttonRef}
          onClick={handleClick}
          display="inline-block"
          component="div"
          {...others}>
          {customButton}
        </Box>
      )
    }

    return (
      <Button ref={buttonRef} onClick={handleClick} endIcon={endIcon} variant={variant} {...others}>
        {children}
      </Button>
    )
  }

  const menuItems = useMemo(
    () =>
      options.map((option, index) => (
        <DropdownButtonItem key={index} index={index} option={option} onSelect={handleItemSelect} />
      )),
    [options, handleItemSelect]
  )

  return (
    <>
      <Tooltip title={tooltipTitle} arrow disableHoverListener={!tooltipTitle} disableInteractive>
        {renderButton()}
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={menuSlotProps}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...MenuProps}>
        {menuItems}
      </Menu>
    </>
  )
}

DropdownButton.displayName = 'DropdownButton'

export default DropdownButton
