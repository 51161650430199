import React, { useMemo } from 'react'

import {
  DateTimePicker as MUIDateTimePicker,
  renderDigitalClockTimeView
} from '@mui/x-date-pickers-pro'

import { isValid } from 'date-fns'

import ReadOnly from 'components/common/inputs/ReadOnly'

import { useFormatDateTime } from 'hooks/useFormatDate'

import type { DateTimePickerProps } from 'components/common/date/DateTimePicker'

import type { TimePickerProps } from '@mui/x-date-pickers-pro'

const viewRenderers: TimePickerProps<Date>['viewRenderers'] = {
  hours: renderDigitalClockTimeView,
  minutes: null,
  seconds: null
}
const timeSteps = { hours: 1, minutes: 30, seconds: 30 }

const DateTimePicker = ({
  defaultValue,
  error,
  errorMessage,
  fullWidth,
  helperText,
  label,
  readOnly,
  readOnlyFormatter,
  ReadOnlyProps,
  slotProps,
  value,
  ...props
}: DateTimePickerProps) => {
  const { format, use24HourClock, formatDateTime } = useFormatDateTime()

  const finalSlotProps = useMemo(
    () => ({
      ...slotProps,
      textField: (state) => ({
        error,
        helperText: error ? errorMessage : helperText,
        fullWidth,
        ...(typeof slotProps?.textField === 'function'
          ? slotProps.textField(state)
          : slotProps?.textField)
      })
    }),
    [slotProps, error, errorMessage, helperText, fullWidth]
  )

  if (readOnly) {
    const readOnlyValue = value ?? defaultValue

    const formattedValue = () => {
      if (!isValid(readOnlyValue)) return ''

      return readOnlyFormatter
        ? readOnlyFormatter(readOnlyValue)
        : formatDateTime(readOnlyValue, format)
    }

    return <ReadOnly label={label} value={formattedValue()} {...ReadOnlyProps} />
  }

  return (
    <MUIDateTimePicker
      value={value}
      label={label}
      format={format}
      ampm={!use24HourClock}
      {...props}
      slotProps={finalSlotProps}
      viewRenderers={viewRenderers}
      timeSteps={timeSteps}
    />
  )
}

export default DateTimePicker
