import { useEffect, useRef } from 'react';
import { DialogContent, DialogContentText, Typography } from '@mui/material';
import { Dialog } from '@eventtemple/eventtemple-ui';
import { type ConfirmDialogProps, confirmable } from 'react-confirm';
import type { DialogProps } from '@mui/material';
interface AsyncCommandDialogProps extends Omit<DialogProps, 'open'> {
  message: string;
  task: () => Promise<unknown>;
}
const AsyncTaskDialog = ({
  message,
  task,
  show,
  proceed,
  cancel,
  ...props
}: ConfirmDialogProps<AsyncCommandDialogProps, unknown>) => {
  const hasRun = useRef(false);
  useEffect(() => {
    const executeTask = async () => {
      // Prevent double execution
      if (hasRun.current) return;
      hasRun.current = true;
      try {
        const result = await task();
        proceed(result);
      } catch (error) {
        cancel(error);
      }
    };
    executeTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Dialog open={show} {...props} maxWidth="xs" fullWidth onClose={cancel} data-sentry-element="Dialog" data-sentry-component="AsyncTaskDialog" data-sentry-source-file="AsyncTaskDialog.tsx">
      <DialogContent sx={{
      p: 4
    }} data-sentry-element="DialogContent" data-sentry-source-file="AsyncTaskDialog.tsx">
        <DialogContentText data-sentry-element="DialogContentText" data-sentry-source-file="AsyncTaskDialog.tsx">
          <Typography variant="h5" data-sentry-element="Typography" data-sentry-source-file="AsyncTaskDialog.tsx">{message}</Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>;
};
export default confirmable(AsyncTaskDialog);