import { Grid2 as Grid, Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import CustomErrorRenderer from 'components/common/CustomErrorRenderer';
import { StaticAssets } from 'types/enums/staticAssets';
const Custom500 = () => {
  const t = useTranslations();
  return <CustomErrorRenderer data-sentry-element="CustomErrorRenderer" data-sentry-component="Custom500" data-sentry-source-file="500.tsx">
      <Grid container direction="column" textAlign="center" rowSpacing={1} size={12} data-sentry-element="Grid" data-sentry-source-file="500.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="500.tsx">
          <Typography variant="h3" data-sentry-element="Typography" data-sentry-source-file="500.tsx">{t('serverError.title')}</Typography>
        </Grid>
        <Grid data-sentry-element="Grid" data-sentry-source-file="500.tsx">
          <Typography variant="body1" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="500.tsx">
            {t('serverError.description')}
          </Typography>
        </Grid>
      </Grid>
      <Grid display="block" width="90%" data-sentry-element="Grid" data-sentry-source-file="500.tsx">
        <img src={StaticAssets.SERVER_ERROR} alt="server-error" />
      </Grid>
    </CustomErrorRenderer>;
};
export default Custom500;