'use client';

import { ErrorBoundary } from '@sentry/nextjs';
import ErrorFallback from 'components/common/ErrorBoundary/ErrorFallback';
import useErrorBoundary from 'hooks/useErrorBoundary';
import useMonitoring from 'hooks/useMonitoring';
import BaseProviders from 'providers/BaseProviders';
import type { ReactNode } from 'react';
interface ErrorFallbackProps {
  error: unknown;
  resetError: () => void;
}
const BaseLayout = ({
  children,
  ...pageProps
}: {
  children: ReactNode;
}) => {
  const {
    appBoundaryRef
  } = useErrorBoundary();
  const {
    newRelicError
  } = useMonitoring();
  const renderErrorFallback = ({
    error,
    resetError
  }: ErrorFallbackProps) => {
    appBoundaryRef.current = resetError;
    newRelicError(error as Error);
    return <ErrorFallback resetError={resetError} data-sentry-element="ErrorFallback" data-sentry-component="renderErrorFallback" data-sentry-source-file="BaseLayout.tsx" />;
  };
  return <BaseProviders {...pageProps} data-sentry-element="BaseProviders" data-sentry-component="BaseLayout" data-sentry-source-file="BaseLayout.tsx">
      <ErrorBoundary fallback={renderErrorFallback} data-sentry-element="ErrorBoundary" data-sentry-source-file="BaseLayout.tsx">{children}</ErrorBoundary>
    </BaseProviders>;
};
export default BaseLayout;