import React, { type ForwardedRef, forwardRef } from 'react'

import { Box, CircularProgress } from '@mui/material'

import type { LoaderProps } from 'components/common/layout/Loader'

const Loader = forwardRef(
  (
    { containerProps, ...props }: LoaderProps,
    ref: ForwardedRef<React.JSX.Element>
  ): React.JSX.Element => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      data-testid="Loader"
      {...containerProps}>
      <CircularProgress {...props} ref={ref} />
    </Box>
  )
)

Loader.displayName = 'Loader'

export default Loader
