import React, { useCallback, useMemo, useState } from 'react'

import type { MouseEvent, SyntheticEvent } from 'react'

import { Edit } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid2 as Grid,
  Typography
} from '@mui/material'

import FormBase from 'components/forms/FormBase/FormBase'

import { defaultDiscardLabel, defaultEditLabel, defaultSubmitLabel } from 'utils/forms'

import type { EditableFormProps } from 'components/forms/EditableForm/EditableForm.types'

import type { SxProps } from '@mui/material'

import type { FieldValues } from 'react-hook-form'

const EditableForm = <T extends FieldValues>({
  AccordionProps,
  AccordionSummaryProps,
  AccordionDetailsProps,
  CardProps,
  children,
  customActions,
  discardButtonProps,
  editable = true,
  expandable = false,
  editButtonProps,
  editButton,
  editMode,
  isLoading,
  onDiscard,
  onEdit,
  onSubmit,
  submitButtonProps,
  subTitle,
  methods,
  title,
  titleCollapseIcon,
  titleExpandIcon,
  formProps,
  showTitleIcon
}: EditableFormProps<T>) => {
  const [expanded, setExpanded] = useState<boolean>(AccordionProps?.defaultExpanded ?? false)

  const handleExpandedChange = useCallback(
    (_: SyntheticEvent, expanded: boolean) => setExpanded(expanded),
    []
  )

  const onDiscardBtnClick = useCallback(
    (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      e.stopPropagation()
      onDiscard()
    },
    [onDiscard]
  )

  const onSubmitButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      e.stopPropagation()
      onSubmit()
    },
    [onSubmit]
  )

  const onEditButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      e.stopPropagation()
      onEdit()
    },
    [onEdit]
  )

  const defaultAccordionSummaryProps = { expandIcon: null }

  const accordionSummarySx = useMemo(
    () =>
      ({
        flexDirection: AccordionSummaryProps?.expandIcon ? 'row-reverse' : 'row',
        ...AccordionSummaryProps?.sx
      }) as SxProps,
    [AccordionSummaryProps?.expandIcon, AccordionSummaryProps?.sx]
  )

  return (
    <Card {...CardProps}>
      <FormBase methods={methods} formProps={formProps}>
        <Accordion
          {...AccordionProps}
          expanded={AccordionProps?.expanded ?? (expandable ? expanded : true)}
          onChange={handleExpandedChange}>
          <AccordionSummary
            {...defaultAccordionSummaryProps}
            {...AccordionSummaryProps}
            sx={accordionSummarySx}
            component="div">
            <Grid
              width="100%"
              container
              flexWrap="nowrap"
              justifyContent="space-between"
              alignItems="center">
              <Grid>
                {typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}
                {typeof subTitle === 'string' ? (
                  <Typography variant="body1">{subTitle}</Typography>
                ) : (
                  subTitle
                )}
              </Grid>
              {showTitleIcon && (
                <Grid flex={1} display="flex">
                  {(AccordionProps?.expanded ?? (expandable ? expanded : true))
                    ? titleCollapseIcon
                    : titleExpandIcon}
                </Grid>
              )}
              <Grid>
                {editable && (
                  <>
                    {editMode && (
                      <>
                        <Button
                          color="error"
                          variant="text"
                          onClick={onDiscardBtnClick}
                          size="small"
                          {...discardButtonProps}>
                          {discardButtonProps?.label || defaultDiscardLabel}
                        </Button>

                        {onSubmit && (
                          <Button
                            onClick={onSubmitButtonClick}
                            variant="text"
                            size="small"
                            loading={isLoading}
                            data-testid={`editable-card-submit-${title}`}
                            {...submitButtonProps}>
                            {submitButtonProps?.label || defaultSubmitLabel}
                          </Button>
                        )}
                      </>
                    )}
                    {!editMode &&
                      (editButton || (
                        <Button
                          color="primary"
                          startIcon={<Edit />}
                          onClick={onEditButtonClick}
                          size="small"
                          data-testid={`editable-card-edit-${title}`}
                          {...editButtonProps}>
                          {editButtonProps?.label || defaultEditLabel}
                        </Button>
                      ))}
                  </>
                )}
                {customActions}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails {...AccordionDetailsProps}>{children}</AccordionDetails>
        </Accordion>
      </FormBase>
    </Card>
  )
}

export default EditableForm
