import { Suspense } from 'react';
import SideNav from 'components/layout/SideNav/SideNav';
import useMainLayout from 'hooks/common/layout/useMainLayout';
const SideNavRenderer = () => {
  const {
    sideNavMini,
    sideNavOpen,
    toggleSideNav,
    toggleSideNavMini,
    isReady
  } = useMainLayout();
  if (!isReady) return null;
  return <Suspense fallback={null} data-sentry-element="Suspense" data-sentry-component="SideNavRenderer" data-sentry-source-file="SideNavRenderer.tsx">
      <SideNav open={sideNavOpen} onClose={toggleSideNav} mini={sideNavMini} toggleSideNavMini={toggleSideNavMini} data-sentry-element="SideNav" data-sentry-source-file="SideNavRenderer.tsx" />
    </Suspense>;
};
export default SideNavRenderer;