import { Box, Grid2 as Grid } from '@mui/material';
import type { ReactNode } from 'react';
const CustomErrorRenderer = ({
  children
}: {
  children: ReactNode;
}) => <Box display="flex" justifyContent="center" alignItems="center" flex="1" minHeight="100vh" data-sentry-element="Box" data-sentry-component="CustomErrorRenderer" data-sentry-source-file="CustomErrorRenderer.tsx">
    <Box display="flex" flexWrap="wrap" justifyContent="center" width="600px" data-sentry-element="Box" data-sentry-source-file="CustomErrorRenderer.tsx">
      <Grid container rowSpacing={6} justifyContent="center" data-sentry-element="Grid" data-sentry-source-file="CustomErrorRenderer.tsx">
        {children}
      </Grid>
    </Box>
  </Box>;
export default CustomErrorRenderer;