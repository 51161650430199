import { Alert, Grid2 as Grid, Link, Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
const criticalErrorsLink = 'https://intercom.help/eventtemple/en/articles/5715073-what-does-your-account-has-critical-errors-mean';
const EmailErrorAlert = ({
  errorMessage
}: {
  errorMessage?: string | null;
}) => {
  const t = useTranslations();
  return <Alert severity="error" data-sentry-element="Alert" data-sentry-component="EmailErrorAlert" data-sentry-source-file="EmailErrorAlert.tsx">
      <Grid container direction="column" rowSpacing={2} data-sentry-element="Grid" data-sentry-source-file="EmailErrorAlert.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="EmailErrorAlert.tsx">
          <Typography color="inherit" data-sentry-element="Typography" data-sentry-source-file="EmailErrorAlert.tsx">{t('weReceivedAnError')}</Typography>
        </Grid>
        <Grid data-sentry-element="Grid" data-sentry-source-file="EmailErrorAlert.tsx">
          <Typography color="inherit" fontWeight={500} data-sentry-element="Typography" data-sentry-source-file="EmailErrorAlert.tsx">
            {errorMessage || t('unknownError')}
          </Typography>
        </Grid>
        <Grid data-sentry-element="Grid" data-sentry-source-file="EmailErrorAlert.tsx">
          <Typography color="inherit" data-sentry-element="Typography" data-sentry-source-file="EmailErrorAlert.tsx">
            {`${t('pleaseTryDisconnecting')} `}
            <Link rel="noreferrer" target="_blank" href={criticalErrorsLink} data-sentry-element="Link" data-sentry-source-file="EmailErrorAlert.tsx">
              {t('learnMore')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Alert>;
};
export default EmailErrorAlert;