import { forwardRef, useMemo } from 'react';
import { Search } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { TextField } from '@eventtemple/eventtemple-ui';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { useDebounceCallback } from 'usehooks-ts';
import styles from 'components/views/filters/SearchInput.module.scss';
import type { TextFieldProps } from '@mui/material';
const DEFAULT_DEBOUNCE_TIMEOUT = 300;
interface SearchInputProps extends Omit<TextFieldProps, 'variant' | 'onChange'> {
  onChange: (value: string) => void;
  value?: string;
  debounce?: boolean;
  debounceTimeout?: number;
  placeholder?: string;
  variant?: 'dark' | 'transparent';
}
export const SearchInput = forwardRef<HTMLDivElement, SearchInputProps>(({
  onChange,
  value,
  placeholder,
  variant,
  debounce,
  debounceTimeout,
  ...props
}: SearchInputProps, ref) => {
  const t = useTranslations();
  const onInputChange = useDebounceCallback(onChange, debounce ? debounceTimeout || DEFAULT_DEBOUNCE_TIMEOUT : 0);
  const computedPlaceholder = useMemo(() => placeholder || t('searchWithDots'), [placeholder, t]);
  return <TextField ref={ref} id="outlined-basic" size="small" type="search" hiddenLabel placeholder={computedPlaceholder} value={value} fullWidth onChange={e => onInputChange(e.target.value)} slotProps={{
    input: {
      className: clsx(styles.searchInput, styles[`searchInput__${variant}`]),
      startAdornment: <InputAdornment position="start">
                <Search />
              </InputAdornment>
    }
  }} {...props} />;
});
SearchInput.displayName = 'SearchInput';