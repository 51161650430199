import React from 'react'
import { useCallback, useMemo } from 'react'

import { useController, useFormContext } from 'react-hook-form'

import ReadOnly from 'components/common/inputs/ReadOnly'

import TextField from 'components/common/inputs/TextField'

import type { TextFieldProps } from 'components/common/inputs/TextField/TextField.types'

import type { FormTextFieldProps } from 'components/forms/FormTextField/FormTextField.types'

const FormTextField = ({
  autoFocus,
  name,
  defaultValue,
  required,
  fullWidth,
  label,
  margin,
  type,
  disabled,
  multiline,
  helperText,
  placeholder,
  endAdornment,
  startAdornment,
  minRows,
  readOnly,
  onValueChange,
  ReadOnlyProps,
  hideError,
  ...props
}: FormTextFieldProps): React.JSX.Element => {
  const { control } = useFormContext()

  const id = `${name}-text-field`

  // prevent number input changing value on scroll and up and down arrow keys
  const numberInputProps = useMemo(
    () =>
      type === 'number'
        ? ({
            onWheel: (e) => (e.target as HTMLInputElement).blur(),
            onKeyDown: (e) => {
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault()
              }
            }
          } as TextFieldProps)
        : {},
    [type]
  )

  const rules = useMemo(() => ({ required }), [required])
  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error }
  } = useController({ name, control, rules, defaultValue })
  const errorMessage = error?.message || ''

  const textFieldInputProps = useMemo(
    () => ({
      endAdornment: endAdornment,
      startAdornment: startAdornment,
      'aria-label': `${name}-text-field`,
      ...props?.InputProps
    }),
    [endAdornment, startAdornment, name, props?.InputProps]
  )

  const onTextFieldChange = useCallback(
    (e) => {
      onChange(e)
      onValueChange?.(e.target.value)
    },
    [onChange, onValueChange]
  )

  if (readOnly) {
    return <ReadOnly label={label} value={value} {...ReadOnlyProps} />
  }

  const rows = multiline ? 4 : 1
  const textFieldHelper = !hideError && errorMessage.length > 0 ? errorMessage : helperText

  return (
    <TextField
      {...props}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      onChange={onTextFieldChange}
      onBlur={onBlur}
      inputRef={ref}
      fullWidth={fullWidth}
      label={label}
      margin={margin}
      value={value || ''}
      error={!hideError && Boolean(error)}
      placeholder={placeholder}
      name={name}
      id={id}
      data-testid={id}
      rows={rows}
      helperText={textFieldHelper}
      type={type}
      disabled={disabled}
      required={required}
      multiline={multiline}
      minRows={minRows}
      InputProps={textFieldInputProps}
      {...numberInputProps}
    />
  )
}

export default FormTextField
