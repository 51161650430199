'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { ReactNode } from 'react';
interface QueryProviderProps {
  children: ReactNode;
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});
const QueryProvider = ({
  children
}: QueryProviderProps) => <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="QueryProvider" data-sentry-source-file="QueryProvider.tsx">
    <ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="QueryProvider.tsx" />
    {children}
  </QueryClientProvider>;
export default QueryProvider;