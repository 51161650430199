import { useCallback, useMemo, useState } from 'react';
import { Box, CardHeader, MenuItem, Select } from '@mui/material';
import { useTranslations } from 'next-intl';
import OptimizedAvatar from 'components/common/avatar/OptimizedAvatar';
import MultiOrgIcon from 'components/layout/MultiOrgIcon';
import GlobalContextPickerItem from 'components/pickers/GlobalContextPickerItem';
import { SearchInput } from 'components/views/filters/SearchInput';
import usePermissions from 'hooks/permissions/usePermissions';
import useChainContextStore from 'store/chainContext';
import useCurrentUserStore from 'store/currentUser';
import type { SxProps } from '@mui/material';
import type { Chain, Organization } from '@repo/et-types';
const SearchItemStyles: SxProps = {
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent'
  },
  cursor: 'default',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 100
};
const headerStyle = {
  padding: 0,
  '& .MuiCardHeader-title': {
    marginLeft: '1.75rem'
  },
  '& .MuiCardHeader-avatar': {
    marginRight: 0,
    position: 'absolute',
    top: '.3rem',
    left: '.5rem'
  },
  '& .MuiAvatar-root': {
    height: '1.75rem',
    width: '1.75rem'
  }
};
const Search = ({
  setSearch,
  search
}: {
  setSearch: (value: string) => void;
  search: string;
}) => <MenuItem onKeyDown={e => e.stopPropagation()} disableRipple disableTouchRipple sx={SearchItemStyles} data-sentry-element="MenuItem" data-sentry-component="Search" data-sentry-source-file="GlobalContextPicker.tsx">
    <SearchInput onChange={value => setSearch(value)} value={search} data-sentry-element="SearchInput" data-sentry-source-file="GlobalContextPicker.tsx" />
  </MenuItem>;

// Ensure the search input is not included in the menus selection highlight.
Search.muiSkipListHighlight = true;
interface GlobalContextPickerProps {
  onSelect?: (item: Organization | Chain) => void;
}
const GlobalContextPicker = ({
  onSelect
}: GlobalContextPickerProps) => {
  const t = useTranslations();
  const [search, setSearch] = useState('');
  const {
    selectedOrganization
  } = useChainContextStore(state => ({
    selectedOrganization: state.selectedOrganization
  }));
  const {
    canViewChainContext
  } = usePermissions();
  const {
    chain,
    organizations
  } = useCurrentUserStore(state => ({
    chain: state.chain,
    organizations: state.organizations
  }));
  const onItemSelect = useCallback((item: Organization | Chain) => {
    onSelect?.(item);
    return setSearch('');
  }, [onSelect]);
  const itemsData = useMemo(() => {
    let data: (Organization | Chain)[] = [...organizations];
    if (canViewChainContext) data.unshift(chain as Chain);
    if (search.length > 0) {
      data = data.filter((item: Chain | Organization) => item.name.toLowerCase().includes(search.toLowerCase()));
    }
    return data;
  }, [chain, organizations, search, canViewChainContext]);
  const items = useMemo(() => {
    const selectItems = itemsData.map(item => <GlobalContextPickerItem key={`${item.type}-${item.id}`} item={item} onClick={() => onItemSelect(item)} />);
    return selectItems;
  }, [onItemSelect, itemsData]);
  return <Box display="flex" flexDirection="column" rowGap={1} width="100%" data-sentry-element="Box" data-sentry-component="GlobalContextPicker" data-sentry-source-file="GlobalContextPicker.tsx">
      <Select fullWidth sx={{
      position: 'relative'
    }} displayEmpty size="small" renderValue={() => <CardHeader sx={headerStyle} subheaderTypographyProps={{
      variant: 'body2'
    }} titleTypographyProps={{
      variant: 'body1'
    }} avatar={selectedOrganization ? <OptimizedAvatar src={selectedOrganization.logo_thumb_url || undefined} /> : <MultiOrgIcon />} title={selectedOrganization?.name || t('chain')} />} data-sentry-element="Select" data-sentry-source-file="GlobalContextPicker.tsx">
        <Search setSearch={setSearch} search={search} data-sentry-element="Search" data-sentry-source-file="GlobalContextPicker.tsx" />
        {items}
      </Select>
    </Box>;
};
export default GlobalContextPicker;