'use client';

import { memo, useCallback, useMemo, useRef } from 'react';
import { Menu } from '@mui/icons-material';
import { AppBar, Grid2 as Grid, IconButton, Paper, Toolbar } from '@mui/material';
import { useToggle } from 'usehooks-ts';
import GlobalSearch from 'components/common/GlobalSearch/GlobalSearch';
import Slot from 'components/common/Slot';
import BackToClassicButton from 'components/layout/TopNav/BackToClassicButton';
import EmailErrorPopover from 'components/layout/TopNav/EmailErrorPopover';
import ProductUpdate from 'components/layout/TopNav/ProductUpdate';
import TopNavProfileButton from 'components/layout/TopNav/TopNavProfileButton';
import GlobalContextPicker from 'components/pickers/GlobalContextPicker';
import UserProfileMenu from 'components/users/UserProfileMenu';
import useChainPermissions from 'hooks/permissions/useChainPermissions';
import useResponsive from 'hooks/useResponsive';
import useCurrentUserStore from 'store/currentUser';
import { SIDE_NAV, TOP_NAV } from 'utils/constants/layout';
type TopNavProps = {
  toggleSideNav: () => void;
  sideNavMini?: boolean;
};
const titleContainerStyles = {
  visibility: {
    lg: 'visible',
    xs: 'hidden'
  },
  display: {
    xs: 'none',
    lg: 'flex'
  }
};
const TopNav = ({
  toggleSideNav,
  sideNavMini
}: TopNavProps) => {
  const lgUp = useResponsive('up', 'lg');
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [userMenuOpen, toggleUserMenu] = useToggle(false);
  const [, toggleSearch] = useToggle(false);
  const {
    isChainAdmin,
    chain
  } = useChainPermissions();
  const [emailSettings, currentUserOrganizations] = useCurrentUserStore(state => [state.email_setting, state.organizations]);
  const showGlobalContextPicker = useMemo(() => isChainAdmin || currentUserOrganizations.length > 0 && (chain?.organizations_count ?? 0) > 1, [isChainAdmin, chain, currentUserOrganizations]);
  const appBarStyles = useCallback(() => ({
    height: TOP_NAV.H_DESKTOP,
    top: 0,
    transform: {
      xs: 'none',
      lg: `translateX(${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px)`
    }
  }), [sideNavMini]);
  const toolBarStyles = useMemo(() => ({
    height: TOP_NAV.H_DESKTOP,
    width: {
      xs: '100%',
      lg: `calc(100% - ${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px)`
    }
  }), [sideNavMini]);
  const emailProviderError = emailSettings?.errored || emailSettings?.error_message;
  const menuButton = useMemo(() => {
    if (lgUp) return null;
    return <Grid>
        <IconButton onClick={toggleSideNav}>
          <Menu />
        </IconButton>
      </Grid>;
  }, [lgUp, toggleSideNav]);
  const productUpdates = useMemo(() => {
    if (!lgUp) return null;
    return <Grid>
        <ProductUpdate />
      </Grid>;
  }, [lgUp]);
  const backToClassicButton = useMemo(() => {
    if (!lgUp) return null;
    return <Grid>
        <BackToClassicButton />
      </Grid>;
  }, [lgUp]);
  const emailProviderErrorPopover = useMemo(() => {
    if (!emailProviderError) return null;
    return <Grid>
        <EmailErrorPopover emailSettings={emailSettings} />
      </Grid>;
  }, [emailProviderError, emailSettings]);
  const globalContextPickerSlot = useMemo(() => {
    if (!showGlobalContextPicker) return null;
    return <Slot name="global-context-picker">
        <GlobalContextPicker onSelect={toggleUserMenu} />
      </Slot>;
  }, [showGlobalContextPicker, toggleUserMenu]);
  const searchMaxWidth = {
    xs: 'unset',
    lg: '20rem'
  };
  return <>
      <AppBar position="fixed" sx={appBarStyles} data-sentry-element="AppBar" data-sentry-source-file="TopNav.tsx">
        <Toolbar square component={Paper} sx={toolBarStyles} data-sentry-element="Toolbar" data-sentry-source-file="TopNav.tsx">
          <Grid container alignItems="center" flexWrap="nowrap" columnSpacing={2} width="100%" data-sentry-element="Grid" data-sentry-source-file="TopNav.tsx">
            {menuButton}
            {/*
              Using display here because the top-nav-page-title element always needs to be present in dom.
              This is because the PageTitle component uses createPortal to render the title in the top nav.
             */}
            <Grid sx={titleContainerStyles} data-sentry-element="Grid" data-sentry-source-file="TopNav.tsx">
              <div id="top-nav-page-title" />
            </Grid>
            <Grid flex="1" display="flex" flexWrap="nowrap" data-sentry-element="Grid" data-sentry-source-file="TopNav.tsx">
              <Grid container flexWrap="nowrap" columnSpacing={2} flex="1" display="flex" alignItems="center" justifyContent="flex-end" data-sentry-element="Grid" data-sentry-source-file="TopNav.tsx">
                <Grid flex="1" maxWidth={searchMaxWidth} data-sentry-element="Grid" data-sentry-source-file="TopNav.tsx">
                  <GlobalSearch toggleSearch={toggleSearch} data-sentry-element="GlobalSearch" data-sentry-source-file="TopNav.tsx" />
                </Grid>
                {backToClassicButton}
                {productUpdates}
                {emailProviderErrorPopover}
                <Grid data-sentry-element="Grid" data-sentry-source-file="TopNav.tsx">
                  <TopNavProfileButton buttonRef={buttonRef} onClick={toggleUserMenu} open={userMenuOpen} data-sentry-element="TopNavProfileButton" data-sentry-source-file="TopNav.tsx" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <UserProfileMenu open={userMenuOpen} doClose={toggleUserMenu} anchorEl={buttonRef.current} data-sentry-element="UserProfileMenu" data-sentry-source-file="TopNav.tsx">
        {globalContextPickerSlot}
      </UserProfileMenu>
    </>;
};
export default memo(TopNav);