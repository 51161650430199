import React, { useCallback, useMemo } from 'react'

import { getDateFromSeconds, getSecondsSinceMidnight } from '@repo/utils/datetime'
import { get as lodashGet } from 'lodash'
import { useController, useFormContext, useFormState } from 'react-hook-form'

import TimePicker from 'components/common/date/TimePicker'

import type { FormTimePickerOffsetProps } from 'components/forms/FormTimePickerOffset/FormTimePickerOffset.types'

const FormTimePickerOffset = ({
  name,
  required,
  slotProps,
  onValueChange,
  ...props
}: FormTimePickerOffsetProps) => {
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''
  const defaultValue = lodashGet(defaultValues, name)
  const rules = useMemo(() => ({ required }), [required])

  const {
    field: { onChange, onBlur, value, ref }
  } = useController({ name, control, defaultValue, rules })

  const timeValue = useMemo(() => (value == 0 || value ? getDateFromSeconds(value) : null), [value])
  const handleOnChange = useCallback(
    (time) => {
      const seconds = getSecondsSinceMidnight(time)

      onValueChange?.(seconds, name)
      onChange(seconds)
    },
    [name, onChange, onValueChange]
  )

  const finalSlotProps = useMemo(
    () => ({
      ...slotProps,
      textField: { onBlur, ...slotProps?.textField }
    }),
    [onBlur, slotProps]
  )

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  return (
    <TimePicker
      {...props}
      inputRef={ref}
      name={name}
      error={isError}
      errorMessage={errorMessage}
      defaultValue={defaultValue}
      onChange={handleOnChange}
      slotProps={finalSlotProps}
      value={timeValue}
    />
  )
}

export default FormTimePickerOffset
