import type { ReactNode } from 'react'
import React, { forwardRef, useCallback, useMemo, useRef } from 'react'

import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect
} from '@mui/material'

import ReadOnly from 'components/common/inputs/ReadOnly'

import generatePlaceholder from 'utils/generatePlaceholder'

import type { SelectProps } from 'components/common/inputs/Select'

import type { ValueLabelPair, ValueNodePair } from '@repo/et-types'

const defaultNone = { label: 'None', value: '' }
const defaultVariant = 'outlined'
const selectStyle = {
  width: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}
const listItemStyle = { whiteSpace: 'normal' }
const placeholderStyle = {
  color: (theme) => `${theme.palette.text.secondary} !important`,
  opacity: 0.75
}

const Select = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      name,
      values,
      value,
      required,
      onChange,
      fullWidth,
      children,
      label,
      margin,
      disabled,
      multiple,
      placeholder: placeholderProp,
      helperText,
      errorMessage,
      size,
      variant = defaultVariant,
      color,
      error,
      readOnly,
      shouldShowNone,
      ReadOnlyProps,
      ...props
    }: SelectProps,
    ref
  ): React.JSX.Element => {
    const id = `${name}-select`
    const labelId = `${id}-label`
    const formControlRef = useRef<HTMLDivElement>(null)

    const renderValue = useCallback(
      (selected: string | number | (string | number)[]): string | ReactNode => {
        if (multiple && Array.isArray(selected)) {
          return selected
            ?.map((e: string | number) => values.find((v) => v.value === e)?.label)
            .join(', ')
        }

        const index = values.findIndex((e: ValueNodePair | ValueLabelPair) => e.value === selected)

        return index >= 0
          ? values[index].label
          : shouldShowNone || readOnly
            ? defaultNone.label
            : ''
      },
      [multiple, values, shouldShowNone, readOnly]
    )

    const renderHelperText = useCallback(() => {
      if (errorMessage) return <FormHelperText className="Mui-error">{errorMessage}</FormHelperText>

      return helperText ? <FormHelperText>{helperText}</FormHelperText> : null
    }, [errorMessage, helperText])

    const menuProps = useMemo(
      () => ({
        PaperProps: { style: { maxHeight: '18rem' } },
        MenuListProps: { style: { width: formControlRef.current?.offsetWidth } }
      }),
      [formControlRef]
    )

    const finalValues = useMemo(
      () => (shouldShowNone || readOnly ? [defaultNone, ...values] : values),
      [shouldShowNone, values, readOnly]
    )
    const finalValue = value || (multiple ? [] : '')

    const placeholder = useMemo(() => {
      if (label && !placeholderProp) return generatePlaceholder(label.toString(), 'Select')

      return placeholderProp
    }, [label, placeholderProp])

    if (readOnly) {
      const readOnlyValue =
        multiple && Array.isArray(value)
          ? value?.map((e: string | number) => values.find((v) => v.value === e)?.label)
          : values.find((v) => v.value === value)?.label

      return <ReadOnly label={label} value={readOnlyValue} {...ReadOnlyProps} />
    }

    return (
      <FormControl
        ref={formControlRef}
        color={color}
        disabled={disabled}
        margin={margin}
        error={error}
        fullWidth={fullWidth}
        required={required}
        variant={variant}
        size={size}
        focused={readOnly || undefined}>
        {label && (
          <InputLabel id={labelId} htmlFor={id}>
            {label}
          </InputLabel>
        )}
        {placeholder && !readOnly && !finalValue ? (
          <InputLabel shrink={false} sx={placeholderStyle}>
            {placeholder}
          </InputLabel>
        ) : null}
        {/* @ts-expect-error -- MUISelect seems to shove "unknown" always into the value type, which breaks type checks. */}
        <MuiSelect<string | number | (string | number)[]>
          {...props}
          ref={ref}
          id={id}
          labelId={labelId}
          label={label}
          readOnly={readOnly}
          displayEmpty={readOnly || props.displayEmpty}
          data-testid={id}
          sx={selectStyle}
          onChange={onChange}
          value={finalValue}
          multiple={multiple}
          MenuProps={menuProps}
          IconComponent={readOnly ? undefined : props.IconComponent}
          renderValue={renderValue}>
          {children
            ? children
            : finalValues.map((e: ValueNodePair | ValueLabelPair, index: number) => (
                <MenuItem
                  key={index}
                  value={e.value}
                  disabled={e.disabled}
                  data-testid={`${id}-option-${index}`}
                  role="option">
                  <ListItemText sx={listItemStyle} primary={e.label} secondary={e.helperText} />
                </MenuItem>
              ))}
        </MuiSelect>
        {renderHelperText()}
      </FormControl>
    )
  }
)

Select.displayName = 'Select'

export default Select
