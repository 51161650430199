import { useMemo } from 'react';
import { Avatar, CardHeader, Chip, Grid2 as Grid, MenuItem } from '@mui/material';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import MultiOrgIcon from 'components/layout/MultiOrgIcon';
import useContextRouter from 'hooks/useContextRouter';
import useCurrentUserRoles from 'hooks/useCurrentUserRoles';
import useChainContextStore from 'store/chainContext';
import { SUPPORTED_APP_CONTEXTS } from 'utils/constants/global';
import type { Chain, Organization } from '@repo/et-types';
import type { MouseEventHandler } from 'react';
type GlobalContextPickerItemProps = {
  key: string;
  item: Chain | Organization;
  onClick: MouseEventHandler<HTMLAnchorElement>;
};
const headerStyle = {
  padding: 0,
  '& .MuiCardHeader-avatar': {
    marginRight: '.5rem'
  },
  '& .MuiAvatar-root': {
    height: '2rem',
    width: '2rem'
  }
};
const GlobalContextPickerItem = ({
  key,
  item,
  onClick
}: GlobalContextPickerItemProps) => {
  const t = useTranslations();
  const {
    selectedChain,
    selectedOrganization
  } = useChainContextStore(state => ({
    selectedChain: state.selectedChain,
    selectedOrganization: state.selectedOrganization
  }));
  const {
    isChainAdmin,
    getOrganizationUserRole
  } = useCurrentUserRoles();
  const {
    pathnameWithoutContext
  } = useContextRouter();
  const isOrganization = item.type === 'organization';
  const selected = isOrganization ? selectedOrganization?.id === item.id : selectedChain?.id === item.id;
  const userRoleString = useMemo(() => {
    let result = '';
    if (isOrganization) {
      result = getOrganizationUserRole(item.id).label;
    } else if (isChainAdmin) result = t('roles.admin');
    return result;
  }, [isChainAdmin, isOrganization, item, getOrganizationUserRole, t]);
  const logoSrc = useMemo(() => item.logo_thumb_url as string || undefined, [item]);
  const href = isOrganization ? `/${SUPPORTED_APP_CONTEXTS.ORG}/${item.uuid}${pathnameWithoutContext}` : `/${SUPPORTED_APP_CONTEXTS.CHAIN}/${item.uuid}${pathnameWithoutContext}`;
  const title = useMemo(() => isOrganization ? <Grid container spacing={2}>
          {item.name}

          {item.subscription?.status == 'canceled' && <Chip label={item.subscription.status} variant="soft" size="small" color="warning" />}
        </Grid> : t('chain'), [isOrganization, item, t]);
  const avatarIcon = useMemo(() => isOrganization ? <Avatar sx={{
    height: '2rem',
    width: '2rem'
  }} src={logoSrc} /> : <MultiOrgIcon />, [isOrganization, logoSrc]);
  return <MenuItem component={Link} href={href} onClick={onClick} onKeyDown={e => e.stopPropagation()} key={key} selected={selected} data-sentry-element="MenuItem" data-sentry-component="GlobalContextPickerItem" data-sentry-source-file="GlobalContextPickerItem.tsx">
      <CardHeader sx={headerStyle} title={title} subheader={userRoleString} subheaderTypographyProps={{
      variant: 'body2'
    }} titleTypographyProps={{
      variant: 'body1'
    }} avatar={avatarIcon} data-sentry-element="CardHeader" data-sentry-source-file="GlobalContextPickerItem.tsx" />
    </MenuItem>;
};
export default GlobalContextPickerItem;