'use client';

import { memo } from 'react';
import { Box } from '@mui/material';
import BaseLayout from 'components/layout/BaseLayout';
import SideNavRenderer from 'components/layout/MainLayout/SideNavRenderer';
import TopNavRenderer from 'components/layout/MainLayout/TopNavRenderer';
import MainAppProviders from 'providers/MainAppProviders';
import MainLayoutContent from './MainLayout/MainLayoutContent';
interface MainLayoutProps {
  children: React.ReactNode;
}
const MainLayout = ({
  children
}: MainLayoutProps) => <BaseLayout data-sentry-element="BaseLayout" data-sentry-component="MainLayout" data-sentry-source-file="MainLayout.tsx">
    <MainAppProviders data-sentry-element="MainAppProviders" data-sentry-source-file="MainLayout.tsx">
      <TopNavRenderer data-sentry-element="TopNavRenderer" data-sentry-source-file="MainLayout.tsx" />
      <Box height="100%" data-sentry-element="Box" data-sentry-source-file="MainLayout.tsx">
        <SideNavRenderer data-sentry-element="SideNavRenderer" data-sentry-source-file="MainLayout.tsx" />
        <MainLayoutContent data-sentry-element="MainLayoutContent" data-sentry-source-file="MainLayout.tsx">{children}</MainLayoutContent>
      </Box>
    </MainAppProviders>
  </BaseLayout>;
export default memo(MainLayout);