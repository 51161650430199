import { isEqual } from 'lodash'
import { createWithEqualityFn } from 'zustand/traditional'

import type { Chain, Organization } from '@repo/et-types'

interface ChainContextState {
  selectedChain?: Chain
  selectedOrganization?: Organization
  setSelectedChain: (chain: Chain | undefined) => void
  setSelectedOrganization: (organization: Organization | undefined) => void
}

const chainContextStoreEqualityFn = (a: ChainContextState, b: ChainContextState) => {
  if (!a || !b) return false

  return isEqual(a, b)
}

const useChainContextStore = createWithEqualityFn<ChainContextState>()(
  (set) => ({
    selectedChain: undefined,
    selectedOrganization: undefined,
    setSelectedChain: (chain: Chain | undefined) =>
      set((state) => ({
        ...state,
        selectedChain: chain,
        selectedOrganization: undefined
      })),
    setSelectedOrganization: (organization: Organization | undefined) =>
      set((state) => ({
        ...state,
        selectedChain: undefined,
        selectedOrganization: organization
      }))
  }),
  (a, b) => chainContextStoreEqualityFn(a as ChainContextState, b as ChainContextState)
)

export default useChainContextStore
