import React, { useCallback, useMemo } from 'react'

import { Box, Paper, Stack } from '@mui/material'

import HorizontalStepper from 'components/common/layout/HorizontalStepper'

import DialogFormFooter from 'components/forms/DialogForm/DialogFormFooter'
import DialogFormHeader from 'components/forms/DialogForm/DialogFormHeader'
import FormBase from 'components/forms/FormBase'

import { DIALOG_TYPES } from 'types/dialog'

import type { DialogFormProps } from 'components/forms/DialogForm/DialogForm.types'

import type { SxProps } from '@mui/material'
import type { Theme } from '@mui/material/styles'

import type { FieldValues } from 'react-hook-form'

const contentWrapperStyles: SxProps<Theme> = { bgcolor: (theme) => theme.palette.background.paper }
const defaultHeaderBoxStyles: SxProps<Theme> = {
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 1205 // setting to 1205 to be above all other elements in the dialog since html editor is 1201 and Mui drawer is 1200
}
const horizontalStepperStyles: SxProps<Theme> = { height: '100%' }
const defaultWithStyles = true
const defaultVariant = DIALOG_TYPES.DRAWER

/**
 * The base component for a form in a dialog. Current dialogs supported are:
 * Drawer & Modal.
 * @component
 */
const DialogForm = <T extends FieldValues>({
  bodyStackProps,
  children,
  component,
  DialogFormFooterProps,
  DialogFormHeaderProps,
  FormBaseProps,
  discardButtonProps,
  footerComponent,
  footerBoxProps,
  formProps,
  headerComponent,
  headerBoxProps,
  headerLabel,
  HorizontalStepperProps,
  isLoading,
  methods,
  noPadding,
  onClose,
  onDiscard,
  onSubmit,
  reverseButtons,
  submitButtonProps,
  variant = defaultVariant,
  contentWrapperProps,
  withStyles = defaultWithStyles
}: DialogFormProps<T>) => {
  const handleDiscardClick = useCallback((): void => {
    if (onDiscard) onDiscard()

    if (methods) methods.reset()
  }, [methods, onDiscard])

  const shouldRenderFooter = onSubmit || onDiscard || footerComponent
  const shouldRenderHeader = headerLabel || headerComponent

  const padding = useMemo(() => {
    if (noPadding) return { px: 0, py: 0 }
    else if (variant === DIALOG_TYPES.DRAWER) return { px: 2, py: 2 }

    return { px: 2, pt: shouldRenderHeader ? 0 : 2, pb: shouldRenderFooter ? 0 : 2 }
  }, [noPadding, shouldRenderFooter, shouldRenderHeader, variant])

  const headerBoxStyles = useMemo(
    () => ({ ...defaultHeaderBoxStyles, ...headerBoxProps?.sx }),
    [headerBoxProps?.sx]
  )

  const renderHeaderComponent = useCallback(() => {
    if (!shouldRenderHeader) return null

    if (headerComponent) {
      return (
        <Box {...headerBoxProps} component={Paper} sx={headerBoxStyles}>
          {headerComponent}
        </Box>
      )
    }

    return (
      <DialogFormHeader
        headerLabel={headerLabel}
        variant={variant}
        onClose={onClose}
        {...DialogFormHeaderProps}
      />
    )
  }, [
    DialogFormHeaderProps,
    headerBoxProps,
    headerComponent,
    headerLabel,
    headerBoxStyles,
    onClose,
    shouldRenderHeader,
    variant
  ])

  const renderFooterComponent = useCallback(() => {
    if (!shouldRenderFooter) return null

    if (footerComponent) {
      return <Box {...footerBoxProps}>{footerComponent}</Box>
    }

    return (
      <DialogFormFooter
        discardButtonProps={discardButtonProps}
        isLoading={isLoading}
        onDiscard={handleDiscardClick}
        onSubmit={onSubmit}
        submitButtonProps={submitButtonProps}
        variant={variant}
        reverseButtons={reverseButtons}
        {...DialogFormFooterProps}
      />
    )
  }, [
    DialogFormFooterProps,
    discardButtonProps,
    footerComponent,
    footerBoxProps,
    handleDiscardClick,
    isLoading,
    onSubmit,
    submitButtonProps,
    shouldRenderFooter,
    variant,
    reverseButtons
  ])

  const renderHorizontalStepper = useCallback(() => {
    if (!HorizontalStepperProps) return null

    return <HorizontalStepper {...HorizontalStepperProps} sx={horizontalStepperStyles} />
  }, [HorizontalStepperProps])

  return (
    <FormBase
      methods={methods}
      component={component}
      formProps={formProps}
      withStyles={withStyles}
      {...FormBaseProps}>
      <Stack height="100%" {...contentWrapperProps} sx={contentWrapperStyles}>
        {renderHeaderComponent()}
        <Stack flex="1" sx={padding} {...bodyStackProps}>
          {renderHorizontalStepper()}
          {children}
        </Stack>
        {renderFooterComponent()}
      </Stack>
    </FormBase>
  )
}

export default DialogForm
