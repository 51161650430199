'use client';

import { memo, useCallback, useEffect } from 'react';
import { omit } from 'lodash';
import useCurrentUserStore from 'store/currentUser';
import getLoginUrl from 'utils/getLoginUrl';
import type { FunctionComponent, ReactNode } from 'react';

// The keys that define a component in React. We want to only copy any extra
// properties from the original component to the WrappedComponent and exclude
// these keys.
const primaryComponentKeys = ['<prototype>', 'name', 'length'];
const withAuthenticationRequired = (Component: FunctionComponent<{
  children: ReactNode;
}>) => {
  const WrappedComponent = (props: {
    children: ReactNode;
  }) => {
    const {
      isAuthenticated,
      isLoading
    } = useCurrentUserStore(state => ({
      isAuthenticated: state.isAuthenticated,
      isLoading: state.isLoading
    }));
    const redirectToLogin = useCallback(() => {
      window.location.href = getLoginUrl(window.location.href);
    }, []);
    useEffect(() => {
      if (!isAuthenticated && !isLoading) redirectToLogin();
    }, [isAuthenticated, isLoading, redirectToLogin]);
    if (isLoading || !isAuthenticated) return null;
    return <Component {...props} data-sentry-element="Component" data-sentry-component="WrappedComponent" data-sentry-source-file="withAuthenticationRequired.tsx" />;
  };
  return memo(Object.assign(WrappedComponent, omit(Component, primaryComponentKeys)));
};
export default withAuthenticationRequired;