import React, { useCallback, useMemo } from 'react'

import type { KeyboardEvent, ReactNode } from 'react'

import { Chip } from '@mui/material'

import TextField from 'components/common/inputs/TextField'
import Loader from 'components/common/layout/Loader'

import generatePlaceholder from 'utils/generatePlaceholder'

import type { AutocompleteProps } from 'components/common/inputs/Autocomplete/Autocomplete.types'

import type { AutocompleteRenderInputParams } from '@mui/material'
import type { ValueLabelPair } from '@repo/et-types'

const AutocompleteInput = <
  T extends ValueLabelPair = ValueLabelPair,
  M extends boolean | undefined = undefined,
  D extends boolean | undefined = undefined,
  F extends boolean | undefined = undefined
>({
  canCreateNew,
  currentValue,
  params,
  TextFieldProps,
  NewValueChip,
  ExistingValueChip,
  readOnlyInput,
  label,
  isError,
  isLoading,
  errorMessage,
  helperText,
  placeholder: placeholderProp,
  required,
  size,
  inputRef
}: {
  params: AutocompleteRenderInputParams
  currentValue: T | T[] | null
} & Omit<AutocompleteProps<T, M, D, F>, 'value' | 'values'>) => {
  const getChip = useCallback((): ReactNode | undefined => {
    if (canCreateNew) {
      if (!Array.isArray(currentValue) && currentValue?.value === 'new') {
        return NewValueChip || <Chip variant="filled" label="New" color="success" size="small" />
      } else if (currentValue) {
        return (
          ExistingValueChip || (
            <Chip variant="filled" label="Existing" color="primary" size="small" />
          )
        )
      }
    }

    return <></>
  }, [canCreateNew, currentValue, NewValueChip, ExistingValueChip])

  const getEndAdornment = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <>
        {getChip()}
        {isLoading ? <Loader data-testid="autocomplete-loading" color="inherit" size={20} /> : null}
        {/* @ts-expect-error -- MUI doesn't cast the slot props properly here. */}
        {TextFieldProps?.slotProps?.input?.endAdornment}
        {params.InputProps?.endAdornment}
      </>
    ),
    [getChip, isLoading, TextFieldProps]
  )

  const slotProps = useMemo(
    () => ({
      ...TextFieldProps?.slotProps,
      input: {
        ...params.InputProps,
        ...TextFieldProps?.slotProps?.input,
        readOnly: readOnlyInput,
        endAdornment: getEndAdornment(params)
      }
    }),
    [TextFieldProps, getEndAdornment, readOnlyInput, params]
  )

  const onKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace') event.stopPropagation()
  }, [])

  // Automatically generate a placeholder if a label is provided and a
  // placeholder is not. We want all of our fields to have a placeholder for
  // accessibility reasons.
  const placeholder = useMemo(() => {
    if (label && !placeholderProp) return generatePlaceholder(label.toString(), 'Select')

    return placeholderProp
  }, [label, placeholderProp])

  return (
    <TextField
      {...TextFieldProps}
      {...params}
      size={size}
      onKeyDown={onKeyDown}
      slotProps={slotProps}
      inputRef={inputRef}
      label={label}
      error={isError}
      helperText={isError ? errorMessage : helperText}
      placeholder={placeholder}
      required={required}
    />
  )
}

export default AutocompleteInput
