import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/client/src/components/MeticulousRecorder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/client/src/providers/BaseProviders.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/client/src/styles/application.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.26.3_next@15.1.6_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@_ibg4qjcvprvgtnm4rf2il46muy/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.6_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_babel-plugi_vmxm7abb7mxxmpcvmmxqndqgxq/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Barlow\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"display\":\"swap\",\"fallback\":[\"Public Sans\",\"sans-serif\"]}],\"variableName\":\"barlow\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/new-relic-agent/NewRelicAgent.tsx");
