import { isValid, parseISO } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'

export const getSecondsSinceMidnight = (date: Date): number =>
  date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds()

export const getDateFromSeconds = (seconds: number): Date => {
  const date = new Date()

  date.setHours(0, 0, 0, 0)
  date.setSeconds(isNaN(Number(seconds)) ? 0 : Number(seconds))
  return date
}

export const getUTCDate = (date = new Date()) =>
  new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)

export const getFullUTCDate = (date: string, time: number = 0) => {
  const dateObj = getUTCDate(new Date(date))

  dateObj.setHours(time / 3600)
  dateObj.setMinutes((time % 3600) / 60)
  dateObj.setSeconds(time % 60)

  return dateObj
}

export const getParsedDate = (date: Date | string, isUTC: boolean = false) => {
  if (!date) return

  let value

  // If the date is a string and in the format 'YYYY-MM-DD', treat it as a UTC date.
  // This behavior is consistent with OFE.
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    value = `${date}T00:00:00.000Z`
    return toZonedTime(value, 'UTC')
  }

  if (!isValid(new Date(date))) return // return undefined if the date is invalid

  value = date instanceof Date ? date.toISOString() : date

  return isUTC ? getUTCDate(parseISO(value)) : parseISO(value)
}
