import React from 'react'
import { useCallback } from 'react'

import { Dialog as MuiDialog } from '@mui/material'

import type { DialogCloseReason, DialogProps } from './Dialog.types'

const Dialog = ({ children, onClose, ...props }: DialogProps) => {
  const handleDialogClose = useCallback(
    (_: unknown, reason: DialogCloseReason) => {
      if (reason === 'escapeKeyDown') {
        onClose?.()
      }
    },
    [onClose]
  )

  return (
    <MuiDialog {...props} onClose={handleDialogClose}>
      {children}
    </MuiDialog>
  )
}

export default Dialog
