import { useEffect } from 'react';
import { useParams } from 'next/navigation';
import useNavigation from 'hooks/common/useNavigation';
import usePermissions from 'hooks/permissions/usePermissions';
import useContextRouter from 'hooks/useContextRouter';
import useChainContextStore from 'store/chainContext';
import useCurrentUserStore from 'store/currentUser';
import Routes from 'types/enums/routes';
import { SUPPORTED_APP_CONTEXTS } from 'utils/constants/global';
import type { ReactNode } from 'react';
interface GlobalContextProviderProps {
  children: ReactNode;
}

/**
 * The GlobalContextProvider is responsible for setting the selected chain and organization
 * based on the context and contextId in the URL. This component is used to set the selected
 * chain and organization in the chain context store. When there is no contextId in the URL,
 * we default to the first organization the user has access to.
 * @component
 */
const GlobalContextProvider = ({
  children
}: GlobalContextProviderProps) => {
  const {
    context,
    contextId
  } = useParams();
  const router = useContextRouter();
  const {
    selectedOrganization,
    selectedChain,
    setSelectedChain,
    setSelectedOrganization
  } = useChainContextStore(state => ({
    selectedOrganization: state.selectedOrganization,
    selectedChain: state.selectedChain,
    setSelectedChain: state.setSelectedChain,
    setSelectedOrganization: state.setSelectedOrganization
  }));
  const {
    chain,
    organizations
  } = useCurrentUserStore(state => ({
    chain: state.chain,
    organizations: state.organizations
  }));
  const {
    canViewChainContext
  } = usePermissions();
  const {
    isLoginPage
  } = useNavigation();
  useEffect(() => {
    // Handle context switching between chains
    if (context === SUPPORTED_APP_CONTEXTS.CHAIN && contextId === chain?.uuid) {
      setSelectedChain(chain);
      return;
    }

    // Handle context switching between orgs
    if (context === SUPPORTED_APP_CONTEXTS.ORG && contextId) {
      const org = organizations.find(o => o.uuid === contextId);
      if (org) {
        setSelectedOrganization(org);
        return;
      }
    }

    // Fallback to the first organization the user has access to if no contextId is provided
    setSelectedOrganization(organizations[0]);
    router.replace(Routes.BOOKINGS, {
      context: SUPPORTED_APP_CONTEXTS.ORG,
      contextId: organizations[0].uuid
    });
  }, [chain, context, contextId, organizations, setSelectedChain, setSelectedOrganization, canViewChainContext, router]);
  if (!selectedOrganization && !selectedChain && !isLoginPage) return null;
  return children;
};
export default GlobalContextProvider;