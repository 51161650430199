import { useEffect } from 'react';
import { DialogContent, DialogTitle, LinearProgress, Typography } from '@mui/material';
import { Dialog } from '@eventtemple/eventtemple-ui';
import { useTranslations } from 'next-intl';
import { type ConfirmDialogProps, confirmable } from 'react-confirm';
import { useReactToPrint } from 'react-to-print';
import type { RefObject } from 'react';
interface PerformPrintDialogProps {
  content: RefObject<Element | Text>;
  onBeforeGetContent?: () => Promise<void>;
  title: string;
}
const PerformPrintDialog = ({
  content,
  onBeforeGetContent,
  title,
  show,
  proceed,
  cancel
}: ConfirmDialogProps<PerformPrintDialogProps, void>) => {
  const t = useTranslations();
  const performPrint = useReactToPrint({
    documentTitle: title,
    contentRef: content,
    onBeforePrint: onBeforeGetContent,
    print: async printIframe => {
      const Html2Pdf = (await import('html2pdf.js'))?.default;
      const printDoc = printIframe?.contentDocument;
      if (!printDoc) return;
      const titleElement = document.createElement('h1');
      titleElement.innerText = title;
      titleElement.style.textAlign = 'center';
      titleElement.style.marginBottom = '1rem';
      printDoc.body.prepend(titleElement);
      const html = printDoc.documentElement.outerHTML;
      const options = {
        margin: 5,
        filename: `${title.toLowerCase().trim().replace(/\s/g, '')}.pdf`,
        html2canvas: {
          scale: 2
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'landscape'
        },
        pagebreak: {
          mode: ['avoid-all', 'css', 'legacy']
        }
      };
      await Html2Pdf(html, options);
      proceed();
    }
  });
  useEffect(() => {
    const performAsyncPrint = async () => {
      await performPrint();
    };
    performAsyncPrint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Dialog open={show} fullWidth onClose={cancel} data-sentry-element="Dialog" data-sentry-component="PerformPrintDialog" data-sentry-source-file="PerformPrintDialog.tsx">
      <LinearProgress data-sentry-element="LinearProgress" data-sentry-source-file="PerformPrintDialog.tsx" />
      <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="PerformPrintDialog.tsx">{t('generatingCalendar')}</DialogTitle>
      <DialogContent sx={{
      px: 3,
      pb: 4
    }} data-sentry-element="DialogContent" data-sentry-source-file="PerformPrintDialog.tsx">
        <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="PerformPrintDialog.tsx">{t('generateCalendarMessage')}</Typography>
      </DialogContent>
    </Dialog>;
};
export default confirmable(PerformPrintDialog);