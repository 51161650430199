import { Button, Grid2 as Grid, Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import CustomErrorRenderer from 'components/common/CustomErrorRenderer';
import { StaticAssets } from 'types/enums/staticAssets';
const ErrorFallback = ({
  resetError
}: {
  resetError: () => void;
}) => {
  const t = useTranslations();
  return <CustomErrorRenderer data-sentry-element="CustomErrorRenderer" data-sentry-component="ErrorFallback" data-sentry-source-file="ErrorFallback.tsx">
      <Grid container direction="column" textAlign="center" rowSpacing={1} size={12} data-sentry-element="Grid" data-sentry-source-file="ErrorFallback.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="ErrorFallback.tsx">
          <Typography variant="h3" data-sentry-element="Typography" data-sentry-source-file="ErrorFallback.tsx">{t('components.ErrorBoundary.somethingWentWrong')}</Typography>
        </Grid>
        <Grid data-sentry-element="Grid" data-sentry-source-file="ErrorFallback.tsx">
          <Typography variant="body1" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="ErrorFallback.tsx">
            {t('components.ErrorBoundary.tryAgain')}
          </Typography>
        </Grid>
      </Grid>
      <Grid display="block" width="90%" data-sentry-element="Grid" data-sentry-source-file="ErrorFallback.tsx">
        <img src={StaticAssets.SERVER_ERROR} alt="server-error" />
      </Grid>
      <Grid textAlign="center" data-sentry-element="Grid" data-sentry-source-file="ErrorFallback.tsx">
        <Button size="large" variant="contained" onClick={resetError} data-sentry-element="Button" data-sentry-source-file="ErrorFallback.tsx">
          {t('components.ErrorBoundary.tryAgain')}
        </Button>
      </Grid>
    </CustomErrorRenderer>;
};
export default ErrorFallback;