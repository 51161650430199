'use client';

import QueryProvider from 'providers/QueryProvider';
import UiProvider from 'providers/UiProvider';
import { ReactConfirmMountPoint } from 'utils/react-confirm';
import type { ReactNode } from 'react';
const BaseProviders = ({
  children
}: {
  children: ReactNode;
}) => <QueryProvider data-sentry-element="QueryProvider" data-sentry-component="BaseProviders" data-sentry-source-file="BaseProviders.tsx">
    <UiProvider data-sentry-element="UiProvider" data-sentry-source-file="BaseProviders.tsx">
      <ReactConfirmMountPoint data-sentry-element="ReactConfirmMountPoint" data-sentry-source-file="BaseProviders.tsx" />
      {children}
    </UiProvider>
  </QueryProvider>;
export default BaseProviders;