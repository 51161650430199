'use client'

import { useCallback } from 'react'

import * as Sentry from '@sentry/nextjs'

import type newrelic from 'newrelic'

declare global {
  interface Window {
    newrelic: typeof newrelic
  }
}

const useMonitoring = () => {
  const sentryError = useCallback((error: Error | string, hint?: object) => {
    Sentry.captureException(error, hint)
  }, [])

  const newRelicError = useCallback((error: Error) => {
    const newrelicAgent = window?.newrelic

    if (newrelicAgent) newrelicAgent?.noticeError(error as Error)
  }, [])

  return { newRelicError, sentryError }
}

export default useMonitoring
