import { Divider, ListItem, ListItemButton, ListItemText, Menu } from '@mui/material';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import AppContextLink from 'components/common/AppContextLink';
import styles from 'components/users/UserProfileMenu.module.scss';
import usePermissions from 'hooks/permissions/usePermissions';
import useLogout from 'hooks/useLogout';
import useSlots from 'hooks/useSlots';
import Routes from 'types/enums/routes';
import { ET_HELP_CENTER_URL } from 'utils/constants/global';
import type { ReactNode } from 'react';
interface UserProfileMenuProps {
  anchorEl: HTMLElement | null;
  doClose: () => void;
  open: boolean;
  children: ReactNode;
}
const UserProfileMenu = ({
  anchorEl,
  doClose,
  open,
  children
}: UserProfileMenuProps) => {
  const t = useTranslations();
  const slots = useSlots({
    children
  });
  const {
    hasRoutePermissions
  } = usePermissions();
  const {
    logout
  } = useLogout();
  return <Menu open={open} onClose={doClose} anchorEl={anchorEl} disableAutoFocusItem anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right'
  }} classes={{
    paper: styles.menuPaper
  }} data-sentry-element="Menu" data-sentry-component="UserProfileMenu" data-sentry-source-file="UserProfileMenu.tsx">
      <ListItem dense sx={{
      px: 1
    }} data-sentry-element="ListItem" data-sentry-source-file="UserProfileMenu.tsx">
        {slots['global-context-picker']}
      </ListItem>
      <ListItem disableGutters disablePadding data-sentry-element="ListItem" data-sentry-source-file="UserProfileMenu.tsx">
        <AppContextLink href={Routes.SETTINGS_USER_PROFILE} typographyProps={{
        color: 'textPrimary'
      }} linkProps={{
        underline: 'none'
      }} data-sentry-element="AppContextLink" data-sentry-source-file="UserProfileMenu.tsx">
          <ListItemButton onClick={doClose} data-sentry-element="ListItemButton" data-sentry-source-file="UserProfileMenu.tsx">
            <ListItemText primary={t('profile')} data-sentry-element="ListItemText" data-sentry-source-file="UserProfileMenu.tsx" />
          </ListItemButton>
        </AppContextLink>
      </ListItem>
      {hasRoutePermissions(Routes.SETTINGS_SUBSCRIPTION) && <ListItem disableGutters disablePadding>
          <AppContextLink href={Routes.SETTINGS_SUBSCRIPTION} typographyProps={{
        color: 'textPrimary'
      }} linkProps={{
        underline: 'none'
      }}>
            <ListItemButton onClick={doClose}>
              <ListItemText primary={t('subscription')} />
            </ListItemButton>
          </AppContextLink>
        </ListItem>}
      <ListItem disableGutters disablePadding data-sentry-element="ListItem" data-sentry-source-file="UserProfileMenu.tsx">
        <ListItemButton href={ET_HELP_CENTER_URL} target="_blank" LinkComponent={Link} data-sentry-element="ListItemButton" data-sentry-source-file="UserProfileMenu.tsx">
          <ListItemText primary={t('learningCenter')} data-sentry-element="ListItemText" data-sentry-source-file="UserProfileMenu.tsx" />
        </ListItemButton>
      </ListItem>
      {slots['mobile-search']}
      <Divider data-sentry-element="Divider" data-sentry-source-file="UserProfileMenu.tsx" />
      <ListItem disableGutters disablePadding data-sentry-element="ListItem" data-sentry-source-file="UserProfileMenu.tsx">
        <ListItemButton className={styles.logout} onClick={() => logout()} data-sentry-element="ListItemButton" data-sentry-source-file="UserProfileMenu.tsx">
          <ListItemText primary={t('logout')} data-sentry-element="ListItemText" data-sentry-source-file="UserProfileMenu.tsx" />
        </ListItemButton>
      </ListItem>
    </Menu>;
};
export default UserProfileMenu;