import { Suspense, useState } from 'react';
import { Report } from '@mui/icons-material';
import { IconButton, Popover } from '@mui/material';
import EmailErrorAlert from 'components/email/EmailErrorAlert';
import type EmailSetting from '@repo/et-types/emailSetting';
const EmailErrorPopover = ({
  emailSettings
}: {
  emailSettings?: EmailSetting;
}) => {
  const [open, setOpen] = useState<boolean>(Boolean(emailSettings?.errored || emailSettings?.error_message));

  // Intentionally using state here for the ref, so we can re-render the
  // component when the ref is set. This is necessary to support showing the
  // popover on initial mount.
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const onButtonClick = () => setOpen(!open);
  return <>
      <IconButton ref={ref => setButtonRef(ref)} color="error" onClick={onButtonClick} data-sentry-element="IconButton" data-sentry-source-file="EmailErrorPopover.tsx">
        <Report data-sentry-element="Report" data-sentry-source-file="EmailErrorPopover.tsx" />
      </IconButton>
      <Suspense fallback={null} data-sentry-element="Suspense" data-sentry-source-file="EmailErrorPopover.tsx">
        <Popover open={open} sx={{
        '& .MuiPopover-paper': {
          padding: 0
        }
      }} onClose={onButtonClick} anchorEl={buttonRef} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }} transformOrigin={{
        vertical: 0,
        horizontal: 'right'
      }} data-sentry-element="Popover" data-sentry-source-file="EmailErrorPopover.tsx">
          <EmailErrorAlert errorMessage={emailSettings?.error_message} data-sentry-element="EmailErrorAlert" data-sentry-source-file="EmailErrorPopover.tsx" />
        </Popover>
      </Suspense>
    </>;
};
export default EmailErrorPopover;