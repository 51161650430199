import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Grid2 as Grid } from '@mui/material'

import { DatePicker, TimePicker, renderDigitalClockTimeView } from '@mui/x-date-pickers-pro'

import { isValid } from 'date-fns'
import { fromZonedTime, toZonedTime } from 'date-fns-tz'
import { get, useFormContext } from 'react-hook-form'

import FormSwitch from 'components/forms/FormSwitch'
import { GRID_COLUMN_SPACING, GRID_ROW_SPACING } from 'src/theme/constants'

import type { FormDateRangePickerV2Props } from 'components/forms/FormDateRangePickerV2/FormDateRangePickerV2.types'

import type { DateView, PickersActionBarAction, TimePickerProps } from '@mui/x-date-pickers-pro'

const datePickerViews: DateView[] = ['year', 'month', 'day']
const datePickerActions: PickersActionBarAction[] = ['cancel', 'today', 'clear', 'accept']
const viewRenderers: TimePickerProps<Date>['viewRenderers'] = {
  hours: renderDigitalClockTimeView,
  minutes: null,
  seconds: null
}
const timeSteps = { hours: 1, minutes: 30, seconds: 30 }

// This component is used for Bookings, Events and other resources with `start_at` and `end_at` fields
// Those resources follow organization timezone. All other resources follow user timezone.
const FormDateRangePickerV2 = ({
  isAllDay = false,
  allDayProps,
  allDayLabel,
  required = true,
  disabled,
  timeZone,
  direction = 'row',
  startAtKey = 'start_at',
  startAtDateLabel,
  startAtTimeLabel,
  endAtKey = 'end_at',
  endAtDateLabel,
  endAtTimeLabel,
  textFieldProps
}: FormDateRangePickerV2Props): React.JSX.Element => {
  const {
    watch,
    setValue,
    formState: { errors }
  } = useFormContext()

  const formStartAt = watch(startAtKey)
  const formEndAt = watch(endAtKey)
  const formAllDay = isAllDay ? true : (watch(allDayProps?.name ?? 'all_day') as boolean)

  const [startAt, setStartAt] = useState<Date | null>(
    formStartAt ? toZonedTime(formStartAt, timeZone) : null
  )
  const [endAt, setEndAt] = useState<Date | null>(
    formEndAt ? toZonedTime(formEndAt, timeZone) : null
  )

  useEffect(() => {
    // handle timezone changes or formStartAt changes externally
    setStartAt(formStartAt ? toZonedTime(formStartAt, timeZone) : null)
  }, [timeZone, formStartAt])

  useEffect(() => {
    // handle timezone changes or formEndAt changes externally
    setEndAt(formEndAt ? toZonedTime(formEndAt, timeZone) : null)
  }, [timeZone, formEndAt])

  const startAtErrorMsg = get(errors, startAtKey)?.message
  const endAtErrorMsg = get(errors, endAtKey)?.message

  const handleStartAtChange = useCallback(
    (newValue: Date | null) => {
      setStartAt(newValue)
      if (!endAt || (newValue && newValue > endAt)) {
        setEndAt(newValue)
      }

      const validStartDate = newValue && isValid(newValue)
      const newDate = validStartDate ? fromZonedTime(newValue, timeZone).toISOString() : null

      setValue(startAtKey, newDate)
      if (!endAt || (newValue && newValue > endAt)) {
        setValue(endAtKey, newDate)
      }
    },
    [endAt, timeZone, setValue, startAtKey, endAtKey]
  )

  const handleEndAtChange = useCallback(
    (newValue: Date | null) => {
      setEndAt(newValue)
      if (!startAt || (newValue && newValue < startAt)) {
        setStartAt(newValue)
      }

      const validEndDate = newValue && isValid(newValue)
      const newDate = validEndDate ? fromZonedTime(newValue, timeZone).toISOString() : null

      setValue(endAtKey, newDate)
      if (!startAt || (newValue && newValue < startAt)) {
        setValue(startAtKey, newDate)
      }
    },
    [startAt, timeZone, setValue, endAtKey, startAtKey]
  )

  const actions = useMemo<PickersActionBarAction[]>(() => {
    if (required) {
      return datePickerActions.filter((action) => action !== 'clear')
    }

    return datePickerActions
  }, [required])

  const startDatePickerSlotProps = useMemo(
    () => ({
      actionBar: { actions },
      textField: {
        fullWidth: true,
        disabled,
        required,
        error: Boolean(startAtErrorMsg),
        helperText: startAtErrorMsg,
        ...textFieldProps
      }
    }),
    [actions, startAtErrorMsg, disabled, required, textFieldProps]
  )

  const startTimePickerSlotProps = useMemo(
    () => ({
      actionBar: { actions: [] },
      textField: {
        fullWidth: true,
        disabled,
        required,
        error: Boolean(startAtErrorMsg),
        helperText: startAtErrorMsg,
        ...textFieldProps
      }
    }),
    [disabled, required, startAtErrorMsg, textFieldProps]
  )

  const endDatePickerSlotProps = useMemo(
    () => ({
      actionBar: { actions },
      textField: {
        fullWidth: true,
        disabled,
        required,
        error: Boolean(endAtErrorMsg),
        helperText: endAtErrorMsg,
        ...textFieldProps
      }
    }),
    [actions, endAtErrorMsg, disabled, required, textFieldProps]
  )

  const endTimePickerSlotProps = useMemo(
    () => ({
      actionBar: { actions: [] },
      textField: {
        fullWidth: true,
        disabled,
        required,
        error: Boolean(endAtErrorMsg),
        helperText: endAtErrorMsg,
        ...textFieldProps
      }
    }),
    [disabled, required, endAtErrorMsg, textFieldProps]
  )

  const gridSize = useMemo(() => (direction === 'column' ? 12 : 6), [direction])

  return (
    <Grid container rowSpacing={GRID_ROW_SPACING} columnSpacing={GRID_COLUMN_SPACING}>
      {!isAllDay && (
        <Grid size={12}>
          <FormSwitch name="all_day" type="switch" label={allDayLabel} {...allDayProps} />
        </Grid>
      )}

      <Grid size={gridSize}>
        <DatePicker
          value={startAt}
          label={startAtDateLabel}
          onChange={handleStartAtChange}
          views={datePickerViews}
          slotProps={startDatePickerSlotProps}
          maxDate={endAt ?? undefined}
        />
      </Grid>

      <Grid size={gridSize}>
        <DatePicker
          value={endAt}
          label={endAtDateLabel}
          onChange={handleEndAtChange}
          views={datePickerViews}
          slotProps={endDatePickerSlotProps}
          minDate={startAt ?? undefined}
        />
      </Grid>

      {!formAllDay && (
        <>
          <Grid size={gridSize}>
            <TimePicker
              value={startAt}
              label={startAtTimeLabel}
              onChange={handleStartAtChange}
              slotProps={startTimePickerSlotProps}
              viewRenderers={viewRenderers}
              timeSteps={timeSteps}
              maxTime={endAt ?? undefined}
              disableIgnoringDatePartForTimeValidation
            />
          </Grid>

          <Grid size={gridSize}>
            <TimePicker
              value={endAt}
              label={endAtTimeLabel}
              onChange={handleEndAtChange}
              slotProps={endTimePickerSlotProps}
              viewRenderers={viewRenderers}
              timeSteps={timeSteps}
              minTime={startAt ?? undefined}
              disableIgnoringDatePartForTimeValidation
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default FormDateRangePickerV2
