import * as Sentry from '@sentry/nextjs'

// Must be:
// - Less than 200 characters
// - Not include newlines, tabulator characters, forward slashes(/), or back slashes(\)
// - Not include .. , . , or spaces
const validGitCommitRef = /(\.\.|[.\s\\/])/g
const gitCommitRef = (process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || 'unknown-commit-ref')
  .replace(validGitCommitRef, '-')
  .substring(0, 200)

const SENTRY_RELEASE = `${gitCommitRef}-${process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}`
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT

const isProduction = SENTRY_ENVIRONMENT === 'production'
const isStaging = SENTRY_ENVIRONMENT === 'staging'

// Run profiling in staging & production.
const getIntegrations = () => {
  const integrations = []

  // Enable profiling in production or staging.
  if (isStaging || isProduction) {
    integrations.push(
      Sentry.browserSessionIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration()
    )
  }

  // Enable replays in production.
  if (isProduction) {
    integrations.push(Sentry.replayIntegration())
  }

  return integrations
}

export default Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.5,
  // Profiles sample-rate is relative to the traces sample-rate
  // Calculated as tracesSampleRate * profilesSampleRate.
  profilesSampleRate: 1.0,
  environment: SENTRY_ENVIRONMENT,
  // Record 1% of non error sessions. We don't really use these as of now so
  // they're just using up our quota for sessions with error replays (Ex. 500
  // sessions = 5 session replays)
  replaysSessionSampleRate: 0.01,
  // Record 100% of all sessions with errors. We want to be able to see how/when
  // an error occurs on the users end.
  replaysOnErrorSampleRate: 1.0,
  integrations: getIntegrations(),
  release: SENTRY_RELEASE
})
