import type { Theme, Components } from '@mui/material/styles';
import { varAlpha } from '../utils';
import { inputBaseClasses } from '@mui/material/InputBase';
import { filledInputClasses } from '@mui/material/FilledInput';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material';

// ----------------------------------------------------------------------

const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  /** **************************************
   * STYLE
   *************************************** */
  styleOverrides: {
    root: ({
      theme
    }) => ({
      [`&.${inputBaseClasses.disabled}`]: {
        '& svg': {
          color: theme.vars.palette.text.disabled
        }
      },
      [`& .${inputBaseClasses.input}:focus`]: {
        borderRadius: 'inherit'
      }
    }),
    input: ({
      theme
    }) => ({
      fontSize: theme.typography.pxToRem(15),
      [theme.breakpoints.down('sm')]: {
        // This will prevent zoom in Safari min font size ~ 16px
        fontSize: theme.typography.pxToRem(16)
      },
      '&::placeholder': {
        opacity: 1,
        color: theme.vars.palette.text.disabled
      }
    })
  }
};

// ----------------------------------------------------------------------

const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  /** **************************************
   * STYLE
   * *************************************** */
  styleOverrides: {
    root: ({
      theme
    }) => ({
      color: `${theme.palette.text.primary} !important`,
      [`&.${inputLabelClasses.disabled}`]: {
        color: `${theme.palette.text.disabled} !important`
      },
      [`&.${inputLabelClasses.error}`]: {
        color: `${theme.palette.error.main} !important`
      }
    }),
    asterisk: ({
      theme
    }) => ({
      color: theme.vars.palette.error.main
    })
  },
  /** **************************************
   * Default Props
   **************************************** */
  defaultProps: {
    // Shrink all labels by default. "notched" should also be defaulted to
    // "true" on the corresponding input component's.
    shrink: true,
    size: 'small'
  }
};

// ----------------------------------------------------------------------

const MuiInput: Components<Theme>['MuiInput'] = {
  /** **************************************
   * STYLE
   *************************************** */
  styleOverrides: {
    underline: ({
      theme
    }) => ({
      '&::before': {
        borderBottomColor: varAlpha(theme.vars.palette.grey['500Channel'], 0.32)
      },
      '&::after': {
        borderBottomColor: theme.vars.palette.text.primary
      }
    })
  }
};

// ----------------------------------------------------------------------

const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  /** **************************************
   * STYLE
   *************************************** */
  styleOverrides: {
    root: ({
      theme
    }) => ({
      [`&.${outlinedInputClasses.focused}`]: {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.vars.palette.text.primary
        }
      },
      [`&.${outlinedInputClasses.error}`]: {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.vars.palette.error.main
        }
      },
      [`&.${outlinedInputClasses.disabled}`]: {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.vars.palette.action.disabledBackground
        }
      },
      backgroundColor: theme.vars.palette.background.paper,
      borderRadius: theme.shape.borderRadius
    }),
    multiline: {
      width: '100%'
    },
    inputMultiline: {
      resize: 'vertical',
      minHeight: '2.5rem'
    },
    notchedOutline: ({
      theme
    }) => ({
      borderColor: varAlpha(theme.vars.palette.grey['500Channel'], 0.48),
      transition: theme.transitions.create(['border-color'], {
        duration: theme.transitions.duration.shortest
      })
    })
  },
  /** **************************************
   * DEFAULT PROPS
   *************************************** */
  defaultProps: {
    // By default, notch all outlined input's.
    notched: true
  }
};

// ----------------------------------------------------------------------

const MuiFilledInput: Components<Theme>['MuiFilledInput'] = {
  /** **************************************
   * DEFAULT PROPS
   *************************************** */
  defaultProps: {
    disableUnderline: true
  },
  /** **************************************
   * STYLE
   *************************************** */
  styleOverrides: {
    root: ({
      theme
    }) => ({
      borderRadius: theme.shape.borderRadius,
      backgroundColor: varAlpha(theme.vars.palette.grey['500Channel'], 0.08),
      '&:hover': {
        backgroundColor: varAlpha(theme.vars.palette.grey['500Channel'], 0.16)
      },
      [`&.${filledInputClasses.focused}`]: {
        backgroundColor: varAlpha(theme.vars.palette.grey['500Channel'], 0.16)
      },
      [`&.${filledInputClasses.error}`]: {
        backgroundColor: varAlpha(theme.vars.palette.error.mainChannel, 0.08),
        [`&.${filledInputClasses.focused}`]: {
          backgroundColor: varAlpha(theme.vars.palette.error.mainChannel, 0.16)
        }
      },
      [`&.${filledInputClasses.disabled}`]: {
        backgroundColor: theme.vars.palette.action.disabledBackground
      }
    })
  }
};
const MuiTextField: Components<Theme>['MuiTextField'] = {
  /** **************************************
   * DEFAULT PROPS
   *************************************** */
  defaultProps: {
    variant: 'outlined',
    size: 'small'
  },
  /** **************************************
   * STYLE
   *************************************** */
  styleOverrides: {}
};

// ----------------------------------------------------------------------

export const textfield = {
  MuiInput,
  MuiInputLabel,
  MuiInputBase,
  MuiFilledInput,
  MuiOutlinedInput,
  MuiTextField
};