import { useCallback, useMemo } from 'react';
import { Circle } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import AppContextLink from 'components/common/AppContextLink';
import SideNavBadge from 'components/layout/SideNav/SideNavBadge';
import useContextRouter from 'hooks/useContextRouter';
import Routes from 'types/enums/routes';
import { getBasePath } from 'utils/helpers';
import type { Theme } from '@mui/material/styles';
import type { SideNavItem as SideNavItemProps } from 'hooks/common/layout/sideNav/useSideNav';
import type { KeyboardEventHandler } from 'react';
const linkStyles = {
  textDecoration: 'none',
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer'
};
const iconStyles = (theme: Theme, mini?: boolean) => ({
  color: theme.palette.common.white,
  marginRight: mini ? 0 : 2,
  // When mini, scale the icon to 3x its size so we counter the scale of the
  // side nav. This prevents the icon from looking stretched.
  transform: mini ? 'scaleX(3)' : 'none'
});
const labelSecondaryStyles = (theme: Theme, isActive?: boolean) => ({
  color: isActive ? theme.palette.primary.light : 'inherit',
  fontWeight: 'semiBold'
});
const buttonStyles = (mini?: boolean) => ({
  borderRadius: 1,
  justifyContent: mini ? 'center' : 'initial'
});
const labelSecondaryProps = (isActive: boolean) => ({
  sx: (theme: Theme) => labelSecondaryStyles(theme, isActive)
});
const SideNavLink = ({
  badgeLabel = 0,
  component,
  external,
  href,
  icon,
  inCollapse,
  label,
  mini,
  onClick
}: {
  mini?: boolean;
  inCollapse?: boolean;
} & SideNavItemProps) => {
  const {
    push,
    pathnameWithoutContext: pathname
  } = useContextRouter();
  const isActive = useMemo(() => {
    if (href === Routes.ROOT) return pathname === Routes.ROOT; // Special case for root href

    const basePathname = getBasePath(pathname as Routes);
    const activePath = Boolean(href && basePathname?.startsWith(href));
    return activePath;
  }, [href, pathname]);

  // The normal way users will navigate is by clicking on the link.
  // However, if tabbing through the links, we need to listen for the
  // enter key on the button to navigate.
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (href) {
        if (external) return window.open(href, '_blank');
        return push(href);
      }
    }
  }, [external, href, push]);
  const renderCollapseIcon = useCallback(() => {
    if (!inCollapse) return null;
    return <ListItemIcon>
        <Circle sx={{
        height: '8px',
        width: '8px'
      }} />
      </ListItemIcon>;
  }, [inCollapse]);
  const renderIcon = useCallback(() => {
    if (!icon) return null;
    return <ListItemIcon sx={theme => iconStyles(theme, mini)}>{icon}</ListItemIcon>;
  }, [icon, mini]);
  const renderLabel = useCallback(() => {
    if (mini) return null;
    return <ListItemText secondary={label} slotProps={{
      secondary: {
        ...labelSecondaryProps(isActive)
      }
    }} />;
  }, [isActive, label, mini]);
  const showTooltip = inCollapse || !mini;
  const badgeVariant = mini ? 'dot' : 'default';
  const linkTarget = external ? '_blank' : undefined;
  const tooltipLabel = showTooltip ? undefined : label;
  const listItemButtonStyles = useMemo(() => buttonStyles(mini), [mini]);
  const renderButton = useCallback(() => <ListItemButton data-nav-mini={mini} selected={isActive} sx={listItemButtonStyles} onKeyDown={handleKeyDown} onClick={onClick}>
        {renderIcon()}
        {renderCollapseIcon()}
        {renderLabel()}
        <SideNavBadge badgeLabel={badgeLabel} variant={badgeVariant} />
      </ListItemButton>, [isActive, badgeLabel, handleKeyDown, mini, onClick, renderCollapseIcon, listItemButtonStyles, renderIcon, renderLabel, badgeVariant]);
  const renderLinkButton = useCallback(() => <AppContextLink typographyProps={{
    component: 'span'
  }} href={String(href)} linkProps={{
    target: linkTarget,
    style: linkStyles
  }}>
        {renderButton()}
      </AppContextLink>, [href, linkTarget, renderButton]);
  const renderContent = useCallback(() => href ? renderLinkButton() : renderButton(), [renderLinkButton, renderButton, href]);
  if (component) return component;
  return <Tooltip title={tooltipLabel} placement="right" arrow disableInteractive={!mini} disableHoverListener={!mini} disableTouchListener={!mini} data-sentry-element="Tooltip" data-sentry-component="SideNavLink" data-sentry-source-file="SideNavLink.tsx">
      <ListItem disableGutters disablePadding data-sentry-element="ListItem" data-sentry-source-file="SideNavLink.tsx">
        {renderContent()}
      </ListItem>
    </Tooltip>;
};
export default SideNavLink;