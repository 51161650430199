import { useCallback, useRef, useState } from 'react';
import { Box, MenuItem, Popover, Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useDebounceValue } from 'usehooks-ts';
import styles from 'components/common/GlobalSearch/GlobalSearch.module.scss';
import SearchResult from 'components/common/GlobalSearch/SearchResult';
import { SearchInput } from 'components/views/filters/SearchInput';
import useGlobalSearch from 'hooks/common/search/useGlobalSearch';
import useContextRouter from 'hooks/useContextRouter';
import Routes from 'types/enums/routes';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants/global';
import type { GlobalSearchResult } from 'types/GlobalSearchResult';
interface GlobalSearchProps {
  toggleSearch: (enabled: boolean) => void;
}
const GlobalSearch = ({
  toggleSearch
}: GlobalSearchProps) => {
  const t = useTranslations();
  const searchRef = useRef<HTMLDivElement>(null);
  const router = useContextRouter();
  const [search, setSearch] = useState('');
  const [debouncedSearchValue] = useDebounceValue(search, SEARCH_DEBOUNCE_TIME / 2);
  const searchLength = debouncedSearchValue.length;
  const {
    data,
    isLoading
  } = useGlobalSearch({
    enabled: searchLength >= 2,
    queryParams: {
      filter: {
        default_search: debouncedSearchValue
      }
    }
  });
  const onItemSelect = useCallback((record: GlobalSearchResult) => {
    setSearch('');
    toggleSearch(false);
    const pathMap = {
      account: Routes.ACCOUNTS,
      contact: Routes.CONTACTS,
      booking: Routes.BOOKINGS
    };
    return router.push(`${pathMap[record.type]}/${record.id}`);
  }, [router, toggleSearch, setSearch]);
  const onClose = () => setSearch('');
  return <Box width="100%" data-sentry-element="Box" data-sentry-component="GlobalSearch" data-sentry-source-file="GlobalSearch.tsx">
      <SearchInput ref={searchRef} placeholder={t('search')} value={search} onChange={term => setSearch(term)} data-sentry-element="SearchInput" data-sentry-source-file="GlobalSearch.tsx" />

      <Popover disableAutoFocus anchorEl={searchRef.current} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }} open={searchLength >= 2} onClose={onClose} data-sentry-element="Popover" data-sentry-source-file="GlobalSearch.tsx">
        <Box className={styles.globalSearch} sx={{
        width: searchRef.current && searchRef.current.offsetWidth
      }} data-sentry-element="Box" data-sentry-source-file="GlobalSearch.tsx">
          {isLoading ? <MenuItem>
              <Typography variant="body2">{t('loading')}</Typography>
            </MenuItem> : null}
          {!isLoading && data.length > 0 && <SearchResult results={data} onClick={onItemSelect} />}
          {!isLoading && data.length === 0 && <MenuItem>
              <Typography variant="body2">{t('noResultsMatch')}</Typography>
            </MenuItem>}
        </Box>
      </Popover>
    </Box>;
};
export default GlobalSearch;