import SideNavCollapsibleItem from 'components/layout/SideNav/SideNavCollapsibleItem';
import SideNavLink from 'components/layout/SideNav/SideNavLink';
import type { SideNavItem as SideNavItemType } from 'hooks/common/layout/sideNav/useSideNav';
export type SideNavItemProps = {
  mini?: boolean;
  inCollapse?: boolean;
} & SideNavItemType;
const SideNavItem = (props: SideNavItemProps) => {
  const {
    subItems,
    hidden
  } = props;
  const hasSubItems = subItems && subItems?.length > 0;
  const allSubItemsHidden = subItems?.every(subItem => subItem?.hidden);
  if (hidden || allSubItemsHidden) return null;
  if (hasSubItems) return <SideNavCollapsibleItem {...props} />;
  return <SideNavLink {...props} data-sentry-element="SideNavLink" data-sentry-component="SideNavItem" data-sentry-source-file="SideNavItem.tsx" />;
};
export default SideNavItem;