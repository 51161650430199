'use client';

import { Button, Grid2 as Grid, Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import CustomErrorRenderer from 'components/common/CustomErrorRenderer';
import useContextRouter from 'hooks/useContextRouter';
import Routes from 'types/enums/routes';
import { StaticAssets } from 'types/enums/staticAssets';
const Custom404 = () => {
  const t = useTranslations();
  const router = useContextRouter();
  return <CustomErrorRenderer data-sentry-element="CustomErrorRenderer" data-sentry-component="Custom404" data-sentry-source-file="404.tsx">
      <Grid container direction="column" textAlign="center" rowSpacing={1} size={12} data-sentry-element="Grid" data-sentry-source-file="404.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="404.tsx">
          <Typography variant="h3" data-sentry-element="Typography" data-sentry-source-file="404.tsx">{t('notFound.title')}</Typography>
        </Grid>
        <Grid data-sentry-element="Grid" data-sentry-source-file="404.tsx">
          <Typography variant="body1" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="404.tsx">
            {t('notFound.description')}
          </Typography>
        </Grid>
      </Grid>
      <Grid display="block" width="90%" data-sentry-element="Grid" data-sentry-source-file="404.tsx">
        <img src={StaticAssets.NOT_FOUND} alt="not-found" />
      </Grid>
      <Grid textAlign="center" data-sentry-element="Grid" data-sentry-source-file="404.tsx">
        <Button size="large" variant="contained" onClick={() => router.push(Routes.ROOT)} data-sentry-element="Button" data-sentry-source-file="404.tsx">
          {t('goToHome')}
        </Button>
      </Grid>
    </CustomErrorRenderer>;
};
export default Custom404;