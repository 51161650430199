import React, { useMemo } from 'react'

import { TextField as MUITextField } from '@mui/material'

import generatePlaceholder from 'utils/generatePlaceholder'

import type { TextFieldProps } from 'components/common/inputs/TextField/TextField.types'

const TextField = ({
  disableDefaultPlaceholder,
  label,
  placeholder: placeholderProp,
  ...props
}: TextFieldProps) => {
  // Automatically generate a placeholder if a label is provided and a
  // placeholder is not. We want all of our fields to have a placeholder for
  // accessibility reasons.
  const placeholder = useMemo(() => {
    if (label && !placeholderProp && !disableDefaultPlaceholder) {
      return generatePlaceholder(label.toString())
    }

    return placeholderProp
  }, [label, placeholderProp, disableDefaultPlaceholder])

  return <MUITextField label={label} placeholder={placeholder} {...props} />
}

export default TextField
