'use client';

import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-tree-view-pro/themeAugmentation';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import type {} from '@mui/material/themeCssVarsAugmentation';
import { memo, ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import InitColorSchemeScript from '@mui/material/InitColorSchemeScript';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v15-appRouter';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import createTheme from './createTheme';
import type { ThemeColorScheme } from './types';
type Props = {
  children: ReactNode;
  colorScheme?: ThemeColorScheme;
};
const theme = createTheme('light');
export const themeBreakpoints = theme.breakpoints;
export const themePalette = theme.palette;
const cacheProviderOptions = {
  key: 'css',
  enableCssLayer: true
};
const themeModeStorageKey = 'theme-mode';
const ThemeProvider = ({
  children
}: Props) => {
  return <AppRouterCacheProvider options={cacheProviderOptions} data-sentry-element="AppRouterCacheProvider" data-sentry-component="ThemeProvider" data-sentry-source-file="index.tsx">
      <MUIThemeProvider theme={theme} defaultMode="light" modeStorageKey={themeModeStorageKey} data-sentry-element="MUIThemeProvider" data-sentry-source-file="index.tsx">
        <InitColorSchemeScript defaultMode="light" attribute="class" modeStorageKey={themeModeStorageKey} data-sentry-element="InitColorSchemeScript" data-sentry-source-file="index.tsx" />
        <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="index.tsx" />
        {children}
      </MUIThemeProvider>
    </AppRouterCacheProvider>;
};
export default memo(ThemeProvider);