import {
  ET_CHAIN_CONTEXT_KEY,
  ET_JWT_LOCAL_STORAGE_KEY,
  ET_REVIEW_APP_API_URL_KEY
} from 'utils/constants/global'

const clearLocalStorageKeys = () => {
  localStorage.removeItem(ET_JWT_LOCAL_STORAGE_KEY)
  localStorage.removeItem(ET_REVIEW_APP_API_URL_KEY)
  localStorage.removeItem(ET_CHAIN_CONTEXT_KEY)
}

export default clearLocalStorageKeys
