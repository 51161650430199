import type { ReactNode } from 'react'
import React, { useMemo } from 'react'

import { ListSubheader, MenuItem } from '@mui/material'

import { get as lodashGet } from 'lodash'
import { useController, useFormContext, useFormState } from 'react-hook-form'

import Select from 'components/common/inputs/Select'

import type { FormGroupSelectProps, GroupedValueLabelPair } from './FormGroupSelect.types'
import type { ValueLabelPair } from '@repo/et-types'

const FormGroupSelect = ({ name, values, required, ...props }: FormGroupSelectProps): ReactNode => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control, name })

  const rules = useMemo(() => ({ required }), [required])

  const {
    field: { onChange, onBlur, ref, value }
  } = useController({ name, control, rules })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const groupedValues = useMemo(
    () =>
      values.reduce((acc, value) => {
        const { groupByValue, groupByLabel } = value

        if (!acc[groupByValue]) {
          acc[groupByValue] = {
            label: groupByLabel,
            options: [] as ValueLabelPair[]
          }
        }

        acc[groupByValue].options.push({
          value: value.value,
          label: value.label
        })
        return acc
      }, {} as GroupedValueLabelPair),
    [values]
  )

  const renderSelectGroup = (
    groupValue: string | number,
    groupLabel: string,
    options: ValueLabelPair[]
  ) => {
    const items = options.map((p, index) => (
      <MenuItem key={p.value} value={p.value} data-testid={`value-${index}`}>
        {p.label}
      </MenuItem>
    ))

    return [<ListSubheader key={groupValue}>{groupLabel}</ListSubheader>, items]
  }

  return (
    <Select
      {...props}
      error={isError}
      errorMessage={errorMessage}
      onChange={onChange}
      onBlur={onBlur}
      onClose={onBlur}
      required={required}
      ref={ref}
      value={value}
      values={values}
      name={name}>
      {Object.keys(groupedValues).map((groupValue) =>
        renderSelectGroup(
          groupValue,
          groupedValues[groupValue].label,
          groupedValues[groupValue].options
        )
      )}
    </Select>
  )
}

export default FormGroupSelect
