import TopNav from 'components/layout/TopNav/TopNav';
import useMainLayout from 'hooks/common/layout/useMainLayout';
import useRouteRedirection from 'hooks/common/layout/useRouteRedirection';
const TopNavRenderer = () => {
  const {
    sideNavMini,
    toggleSideNav,
    isReady
  } = useMainLayout();
  useRouteRedirection();
  if (!isReady) return null;
  return <TopNav sideNavMini={sideNavMini} toggleSideNav={toggleSideNav} data-sentry-element="TopNav" data-sentry-component="TopNavRenderer" data-sentry-source-file="TopNavRenderer.tsx" />;
};
export default TopNavRenderer;