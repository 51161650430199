import { useMutation } from '@tanstack/react-query'

import useApiSnackBar from 'hooks/useApiSnackBar'
import api from 'utils/api'
import clearLocalStorageKeys from 'utils/clearLocalStorageKeys'
import { SIGN_IN_URL, SIGN_OUT_URL } from 'utils/constants/global'

import type { AxiosError } from 'axios'

const useLogout = () => {
  const { showError } = useApiSnackBar()

  const logoutMutation = useMutation({
    mutationFn: async () => api.delete(SIGN_OUT_URL),
    onError: (error: AxiosError) => showError({ error }),
    onSuccess: () => {
      clearLocalStorageKeys()
      window.location.href = SIGN_IN_URL
    }
  })

  return { logout: logoutMutation.mutate }
}

export default useLogout
