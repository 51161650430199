export const SIGN_OUT_URL = `${process.env.NEXT_PUBLIC_APP_URL}/users/sign_out.json`
export const SIGN_IN_URL =
  process.env.NEXT_PUBLIC_AUTH_MODE === 'JWT' ? '/auth/login' : `${process.env.NEXT_PUBLIC_APP_URL}`
export const ET_HELP_CENTER_URL = 'https://intercom.help/eventtemple/'
export const CLASSIC_ET_APP_MULTI_ORG_URL = `${process.env.NEXT_PUBLIC_APP_URL}/chain/bookings/list`
export const SEARCH_DEBOUNCE_TIME = 500
export const RESOURCE_ATTACHMENT_FILE_SIZE_MB = 50
export const LEGACY_APP_URL = `${process.env.NEXT_PUBLIC_APP_URL}`
export const CANNY_APP_ID = process.env.NEXT_PUBLIC_CANNY_APP_ID || ''
export const SUPPORTED_APP_CONTEXTS = {
  CHAIN: 'chain',
  ORG: 'organization'
}

export const ET_CHAIN_CONTEXT_KEY = 'chain-context'
// used for pointing to review apps. only used in JWT mode for review apps
export const ET_REVIEW_APP_API_URL_KEY = 'et-api-review-app-api-url'
export const ET_JWT_LOCAL_STORAGE_KEY = 'et-jwt'
