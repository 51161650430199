'use client';

import { memo, useCallback, useLayoutEffect, useRef } from 'react';
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';
import type { LoaderOptions, Recorder } from '@alwaysmeticulous/recorder-loader';
const isBrowser = typeof window !== 'undefined';
const shouldSetupRecorder = process.env.VERCEL_ENV === 'preview' || process.env.NODE_ENV === 'development';
const setupMeticulousRecorder = async () => {
  const config: LoaderOptions = {
    recordingToken: String(process.env.NEXT_PUBLIC_METICULOUS_KEY),
    isProduction: false,
    // If the recorder takes longer than this to load, it will be skipped.
    maxMsToBlockFor: 250,
    // How often to upload recordings (default is 5s). We set it to 45s to reduce the number of uploads.
    uploadIntervalMs: 60000
  };
  return tryLoadAndStartRecorder(config);
};

/**
 * Component that sets up the Meticulous Recorder.
 * This is only used in development & preview environments.
 */
const MeticulousRecorder = () => {
  const meticulousRef = useRef<Recorder | null>(null);
  const attemptMeticulousSetup = useCallback(async () => {
    if (!isBrowser || meticulousRef?.current || !shouldSetupRecorder) return;
    try {
      const meticulous = await setupMeticulousRecorder();
      if (meticulous) meticulousRef.current = meticulous;
    } catch (error) {
      console.error('Failed to setup Meticulous Recorder', error);
    }
  }, []);
  useLayoutEffect(() => {
    attemptMeticulousSetup();
  }, [attemptMeticulousSetup]);
  return null;
};
export default memo(MeticulousRecorder);