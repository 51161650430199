'use client';

import { useEffect } from 'react';
import Custom500 from 'components/500';
import useMonitoring from 'hooks/useMonitoring';
const GlobalError = ({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) => {
  const {
    newRelicError,
    sentryError
  } = useMonitoring();
  useEffect(() => {
    if (!error) return;
    sentryError(error);
    newRelicError(error);
  }, [error, newRelicError, sentryError]);
  return <html lang="en" data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <Custom500 data-sentry-element="Custom500" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
};
export default GlobalError;