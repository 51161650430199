import type { Theme, Components } from '@mui/material/styles';

// ----------------------------------------------------------------------

// @ts-expect-error - Type doesn't exist on theme yet
const MuiTreeItem: Components<Theme>['MuiTreeItem'] = {
  /** **************************************
   * STYLE
   *************************************** */
  styleOverrides: {
    label: ({
      theme
    }: {
      theme: Theme;
    }) => ({
      ...theme.typography.body2
    }),
    iconContainer: {
      width: 'auto'
    }
  }
};

// ----------------------------------------------------------------------

export const treeView = {
  MuiTreeItem
};