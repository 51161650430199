import { useMemo } from 'react';
import { Apartment, Block, Bookmark, CalendarMonth, Description, Money, RequestQuote, Task } from '@mui/icons-material';
import { useTranslations } from 'next-intl';
import usePermissions from 'hooks/permissions/usePermissions';
import { OrganizationFeatures } from 'types/enums/featureFlags';
import type SetupValueType from '@repo/et-types/setupValueType';
const filterDisabled = (options: SetupValueType[]) => options.filter(option => !option.disabled);
const useSetupValueOptions = () => {
  const t = useTranslations();
  const {
    canUseOrgFeature
  } = usePermissions();
  const options = useMemo<SetupValueType[]>(() => {
    const tabs = [{
      id: 1,
      field_name: t('revenueCategories'),
      field_value: 'revenue_categories',
      used_for: [t('menuItems'), t('lineItems')],
      type: 'setup_values',
      icon: <Money />
    }, {
      id: 2,
      field_name: t('referralSources'),
      field_value: 'referral_sources',
      used_for: [t('bookings'), t('groups'), t('accounts')],
      type: 'setup_values',
      icon: <Bookmark />
    }, {
      id: 3,
      field_name: t('lostReasons'),
      field_value: 'lost_reasons',
      used_for: [t('bookings')],
      type: 'setup_values',
      icon: <Bookmark />
    }, {
      id: 4,
      field_name: t('bookingTypes'),
      field_value: 'booking_types',
      used_for: [t('bookings')],
      type: 'setup_values',
      icon: <Bookmark />
    }, {
      id: 5,
      field_name: t('taskTypes'),
      field_value: 'task_types',
      used_for: [t('tasks')],
      type: 'setup_values',
      icon: <Task />
    }, {
      id: 6,
      field_name: t('eventTypes'),
      field_value: 'event_types',
      used_for: [t('events')],
      type: 'setup_values',
      icon: <CalendarMonth />
    }, {
      id: 7,
      field_name: t('eventSetupStyles'),
      field_value: 'setup_styles',
      used_for: [t('events')],
      type: 'setup_values',
      icon: <CalendarMonth />
    }, {
      id: 8,
      field_name: t('accountTypes'),
      field_value: 'account_types',
      used_for: [t('accounts')],
      type: 'setup_values',
      icon: <Apartment />
    }, {
      id: 9,
      field_name: t('marketSegments'),
      field_value: 'market_segments',
      used_for: [t('accounts')],
      type: 'setup_values',
      icon: <Apartment />
    }, {
      id: 10,
      field_name: t('documentTypes'),
      field_value: 'document_types',
      used_for: [t('documents')],
      type: 'setup_values',
      icon: <Description />,
      hidden: !canUseOrgFeature(OrganizationFeatures.DOCUMENTS)
    }, {
      id: 11,
      field_name: t('paymentTypes'),
      field_value: 'payment_types',
      used_for: [t('invoices')],
      type: 'setup_values',
      icon: <RequestQuote />,
      hidden: !canUseOrgFeature(OrganizationFeatures.INVOICES)
    }, {
      id: 12,
      field_name: t('spaceClosureReasons'),
      field_value: 'space_closure_reasons',
      used_for: [t('spaces')],
      type: 'setup_values',
      icon: <Block />
    }];
    return tabs.filter(tab => !tab.hidden);
  }, [t, canUseOrgFeature]);
  return filterDisabled(options);
};
export default useSetupValueOptions;