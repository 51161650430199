'use client';

import { useEffect } from 'react';
import ErrorComponent from 'components/500';
import useMonitoring from 'hooks/useMonitoring';
const ErrorPage = ({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) => {
  const {
    newRelicError,
    sentryError
  } = useMonitoring();
  useEffect(() => {
    if (!error) return;
    sentryError(error);
    newRelicError(error);
  }, [error, newRelicError, sentryError]);
  return <ErrorComponent data-sentry-element="ErrorComponent" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx" />;
};
export default ErrorPage;