import useCurrentUserStore from 'store/currentUser'

const useChainPermissions = () => {
  const { chain, isChainAdmin } = useCurrentUserStore((state) => ({
    chain: state.chain,
    isChainAdmin: state.chain_admin
  }))
  const selectedChain = useCurrentUserStore((state) => state.selectedChain)

  const globalAccountsEnabled = Boolean(chain?.global_accounts)

  return { chain, chainId: chain?.id, isChainAdmin, globalAccountsEnabled, selectedChain }
}

export default useChainPermissions
