import { Alert, Typography } from '@mui/material';
import Link from 'next/link';
import { useSessionStorage } from 'usehooks-ts';
import { ET_REVIEW_APP_API_URL_KEY } from 'utils/constants/global';
const isBrowser = typeof window !== 'undefined';
const showReviewAppAlertKey = 'showReviewAppAlert';
const PreviewAppAlert = () => {
  const [showReviewAppAlert, setShowReviewAppAlert] = useSessionStorage(showReviewAppAlertKey, true);
  const hideReviewAppAlert = () => setShowReviewAppAlert(false);
  const reviewAppUrl = isBrowser ? localStorage.getItem(ET_REVIEW_APP_API_URL_KEY) : '';
  if (!showReviewAppAlert || !reviewAppUrl) return null;
  return <Alert severity="warning" onClose={hideReviewAppAlert} data-sentry-element="Alert" data-sentry-component="PreviewAppAlert" data-sentry-source-file="PreviewAppAlert.tsx">
      <Typography color="inherit" data-sentry-element="Typography" data-sentry-source-file="PreviewAppAlert.tsx">Using an alternative API URL for this environment.</Typography>
      Backend API URL:{' '}
      <Link target="_blank" href={reviewAppUrl} data-sentry-element="Link" data-sentry-source-file="PreviewAppAlert.tsx">
        {reviewAppUrl}
      </Link>
    </Alert>;
};
export default PreviewAppAlert;