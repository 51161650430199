import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronRight } from '@mui/icons-material';
import { Button, Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import SideNavItem from 'components/layout/SideNav/SideNavItem';
import useContextRouter from 'hooks/useContextRouter';
import { getBasePath } from 'utils/helpers';
import type { Theme, TypographyProps } from '@mui/material';
import type { SideNavItemProps } from 'components/layout/SideNav/SideNavItem';
import type { MouseEvent } from 'react';
import type Routes from 'types/enums/routes';
const dropdownIconStyles = (mini: boolean, collapsed: boolean) => ({
  transform: collapsed || mini ? `rotate(0deg) ${mini && 'scaleX(3)'}` : 'rotate(90deg)',
  position: !mini ? 'relative' : 'absolute',
  right: mini ? '2rem' : 'auto',
  color: (theme: Theme) => mini ? theme.palette.common.white : theme.palette.text.secondary,
  margin: 0,
  transition: (theme: Theme) => theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
    easing: theme.transitions.easing.easeInOut
  })
});
const itemButtonStyles = (mini: boolean, showDropdownButton: boolean) => ({
  borderRadius: 1,
  justifyContent: mini ? 'center' : 'initial',
  borderTopRightRadius: showDropdownButton ? 0 : 4,
  borderBottomRightRadius: showDropdownButton ? 0 : 4
});
const iconStyles = (mini: boolean) => ({
  color: (theme: Theme) => theme.palette.common.white,
  marginRight: mini ? 0 : 2,
  // When mini, scale the icon to 3x its size so we counter the scale of the
  // side nav. This prevents the icon from looking stretched.
  transform: mini ? 'scaleX(3)' : 'none'
});
const typographyStyles = (theme: Theme, isActive: boolean, collapseOpen: boolean) => ({
  color: isActive && !collapseOpen ? theme.palette.primary.light : 'inherit',
  fontWeight: isActive && !collapseOpen ? 'semiBold' : 'normal'
});
const buttonStyles = (theme: Theme) => ({
  minWidth: 'unset',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderLeft: `1px solid ${theme.palette.divider}`,
  height: '40px'
});
const SideNavCollapsibleItem = ({
  mini,
  icon,
  label,
  variant,
  subItems,
  onItemHover,
  onClick,
  showArrow,
  defaultExpanded
}: SideNavItemProps) => {
  const {
    pathnameWithoutContext: pathname
  } = useContextRouter();
  const isDropdown = variant === 'dropdown';
  const isHeader = variant === 'header';
  const initialCollapsed = useMemo(() => isDropdown || (isHeader && !defaultExpanded && subItems ? !subItems.some(item => item.href === pathname || item.subItems?.some(subItem => subItem.href === pathname)) : false), [isDropdown, isHeader, defaultExpanded, subItems, pathname]);
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  const collapseOpen = mini ? !icon : !collapsed;
  const isActive = useMemo(() => {
    if (subItems) {
      const basePathname = getBasePath(pathname as Routes);
      return subItems.some(item => item.href && basePathname?.startsWith(item.href));
    }
    return false;
  }, [pathname, subItems]);

  // Since we are using scaleX to animate the side nav, we need to adjust the
  // position of the icon's a tiny bit to the left. This is because the icon's
  // are not scaled down.
  const collapseStyle = useMemo(() => ({
    transform: mini ? 'translateX(-.5rem)' : 'none',
    pl: icon ? 3.5 : 0,
    transition: mini ? 'none' : undefined
  }), [mini, icon]);
  useEffect(() => {
    if (mini && collapsed && variant === 'header') setCollapsed(false);
    if (mini && !collapsed && variant === 'dropdown') setCollapsed(true);
  }, [collapsed, mini, variant]);
  const typographyProps: TypographyProps = {
    variant: variant === 'header' ? 'overline' : 'body2',
    sx: theme => typographyStyles(theme, isActive, collapseOpen)
  };
  const showButton = mini && icon || !mini;
  const handleOnMouseEnter = (e: MouseEvent<HTMLLIElement>) => onItemHover?.(e, subItems || []);
  const handleOnMouseLeave = () => onItemHover?.(null, []);
  const listItemButtonClick = useCallback(() => {
    if (mini) return;
    onClick?.();
    if (isHeader || isDropdown && collapsed) setCollapsed(!collapsed);
  }, [mini, onClick, isDropdown, collapsed, isHeader]);
  const onMouseEnter = (e: MouseEvent<HTMLLIElement>) => {
    if (isDropdown && mini) return handleOnMouseEnter(e);
    return undefined;
  };
  const onMouseLeave = () => {
    if (isDropdown && mini) return handleOnMouseLeave();
    return undefined;
  };
  const isSelected = isActive && !collapseOpen;
  const showDropdown = Boolean(isDropdown || showArrow);
  const listButtonStyles = itemButtonStyles(Boolean(mini), showDropdown && !isHeader);
  const listIconStyles = iconStyles(Boolean(mini));
  const finalDropdownIconStyles = dropdownIconStyles(Boolean(mini), collapsed);
  const renderItemText = () => {
    if (mini) return null;
    return <ListItemText secondary={label} slotProps={{
      secondary: {
        ...typographyProps
      }
    }} data-sentry-element="ListItemText" data-sentry-component="renderItemText" data-sentry-source-file="SideNavCollapsibleItem.tsx" />;
  };
  const renderDropdownIcon = () => {
    if (!showDropdown || isDropdown && !mini) return null;
    return <ListItemIcon sx={finalDropdownIconStyles} data-sentry-element="ListItemIcon" data-sentry-component="renderDropdownIcon" data-sentry-source-file="SideNavCollapsibleItem.tsx">
        <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="SideNavCollapsibleItem.tsx" />
      </ListItemIcon>;
  };
  const renderDropdownButton = () => {
    if (!showDropdown || isHeader || mini) return null;
    return <Button variant="text" onClick={() => setCollapsed(!collapsed)} sx={buttonStyles} data-sentry-element="Button" data-sentry-component="renderDropdownButton" data-sentry-source-file="SideNavCollapsibleItem.tsx">
        <ChevronRight sx={finalDropdownIconStyles} data-sentry-element="ChevronRight" data-sentry-source-file="SideNavCollapsibleItem.tsx" />
      </Button>;
  };
  const renderButton = () => {
    if (!showButton) return null;
    return <ListItem disableGutters disablePadding onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} data-sentry-element="ListItem" data-sentry-component="renderButton" data-sentry-source-file="SideNavCollapsibleItem.tsx">
        <ListItemButton selected={isSelected} sx={listButtonStyles} disableGutters={!icon} onClick={listItemButtonClick} data-sentry-element="ListItemButton" data-sentry-source-file="SideNavCollapsibleItem.tsx">
          <ListItemIcon sx={listIconStyles} data-sentry-element="ListItemIcon" data-sentry-source-file="SideNavCollapsibleItem.tsx">{icon}</ListItemIcon>
          {renderItemText()}
          {renderDropdownIcon()}
        </ListItemButton>
        {renderDropdownButton()}
      </ListItem>;
  };
  const renderSubMenuItems = () => subItems?.map((subItem, index) => <SideNavItem key={index} mini={mini} inCollapse={isDropdown} onItemHover={onItemHover} {...subItem} />);
  return <>
      {renderButton()}
      <Collapse in={collapseOpen} sx={collapseStyle} data-sentry-element="Collapse" data-sentry-source-file="SideNavCollapsibleItem.tsx">
        <Stack data-sentry-element="Stack" data-sentry-source-file="SideNavCollapsibleItem.tsx">{renderSubMenuItems()}</Stack>
      </Collapse>
    </>;
};
export default SideNavCollapsibleItem;