'use client';

import CannyProvider from 'providers/CannyProvider';
import GlobalContextProvider from 'providers/GlobalContextProvider';
import IntercomProvider from 'providers/IntercomProvider';
import ProfitwellProvider from 'providers/ProfitwellProvider';
import UserProvider from 'providers/UserProvider';
import type { ReactNode } from 'react';
const MainAppProviders = ({
  children
}: {
  children: ReactNode;
}) => <UserProvider data-sentry-element="UserProvider" data-sentry-component="MainAppProviders" data-sentry-source-file="MainAppProviders.tsx">
    <ProfitwellProvider data-sentry-element="ProfitwellProvider" data-sentry-source-file="MainAppProviders.tsx">
      <CannyProvider data-sentry-element="CannyProvider" data-sentry-source-file="MainAppProviders.tsx">
        <IntercomProvider data-sentry-element="IntercomProvider" data-sentry-source-file="MainAppProviders.tsx">
          <GlobalContextProvider data-sentry-element="GlobalContextProvider" data-sentry-source-file="MainAppProviders.tsx">{children}</GlobalContextProvider>
        </IntercomProvider>
      </CannyProvider>
    </ProfitwellProvider>
  </UserProvider>;
export default MainAppProviders;