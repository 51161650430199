import { useEffect, useMemo, useState } from 'react';
import { AccountCircle, AccountTree, Adjust, Api, Apps, ArrowBack, AttachMoney, AutoAwesomeMotion, Bolt, Calculate, CreditCard, DeveloperBoard, DynamicForm, EventAvailable, Hotel, ImportExport, InsertDriveFile, Inventory, LabelImportant, Link, LocationCity, Mail, MeetingRoom, PermMedia, Public, RequestQuote, Restaurant, RoomService, Smartphone, SupervisorAccount, TextFields, Tune } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { usePathname } from 'next/navigation';
import { SearchTextField } from '@eventtemple/eventtemple-ui';
import { useTranslations } from 'next-intl';
import AppContextLink from 'components/common/AppContextLink';
import usePermissions from 'hooks/permissions/usePermissions';
import useSetupValueOptions from 'hooks/setupValues/useSetupValueOptions';
import useLegacyAppUrl from 'hooks/useLegacyAppUrl';
import useChainContextStore from 'store/chainContext';
import { OrganizationFeatures } from 'types/enums/featureFlags';
import Routes from 'types/enums/routes';
import type { Theme } from '@mui/material/styles';
import type { SideNavItem } from 'hooks/common/layout/sideNav/useSideNav';
const returnToAppStyle = {
  color: (theme: Theme) => theme.palette.grey[200]
};
const searchInputStyle = {
  input: {
    color: 'white'
  }
};
const useSettingSideNavItems = (): SideNavItem[] => {
  const [search, setSearch] = useState('');
  const pathName = usePathname();
  const {
    selectedOrganization
  } = useChainContextStore(state => ({
    selectedOrganization: state.selectedOrganization
  }));
  const {
    hasRoutePermissions,
    canUseOrgFeature
  } = usePermissions();
  const canViewBookingPage = hasRoutePermissions(Routes.BOOKINGS);
  const legacyAppUrl = useLegacyAppUrl();
  const t = useTranslations();
  const returnToAppUrl = useMemo(() => {
    // Check if we can send them to the bookings page based on their feature flags.
    if (canViewBookingPage) return Routes.BOOKINGS;
    return legacyAppUrl;
  }, [canViewBookingPage, legacyAppUrl]);
  useEffect(() => {
    if (!pathName?.includes(Routes.SETTINGS)) {
      setSearch('');
    }
  }, [pathName]);
  const setupValueOptions = useSetupValueOptions();
  const items: SideNavItem[] = useMemo(() => {
    const defaultExpanded = Boolean(search && search.length > 0);
    return [{
      component: <Box display="flex" flex="1" flexDirection="column">
            <AppContextLink disableContext={!canViewBookingPage} disableCustomLink href={returnToAppUrl}>
              <Button fullWidth startIcon={<ArrowBack />} variant="outlined" sx={returnToAppStyle}>
                {t('returnToApp')}
              </Button>
            </AppContextLink>
            <Box my={2}>
              <SearchTextField onValueChange={setSearch} value={search} sx={searchInputStyle} variant="filled" />
            </Box>
          </Box>
    }, {
      label: t('personalSettings'),
      variant: 'header',
      showArrow: true,
      subItems: [{
        label: t('navigation.userProfile.title'),
        icon: <AccountCircle />,
        href: Routes.SETTINGS_USER_PROFILE
      }, {
        label: t('navigation.email.title'),
        description: t('navigation.email.description'),
        icon: <Mail />,
        href: Routes.SETTINGS_EMAIL,
        hidden: !hasRoutePermissions(Routes.SETTINGS_EMAIL)
      }]
    }, {
      label: selectedOrganization ? t('organization') : t('chain'),
      variant: 'header',
      showArrow: true,
      defaultExpanded,
      subItems: [{
        label: t('navigation.general.title'),
        description: t('navigation.general.description'),
        icon: <Tune />,
        href: Routes.SETTINGS_GENERAL_SETTINGS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_GENERAL_SETTINGS)
      }, {
        label: t('navigation.usersAndRoles.title'),
        description: t('navigation.usersAndRoles.description'),
        icon: <SupervisorAccount />,
        href: Routes.SETTINGS_USERS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_USERS)
      }, {
        label: t('navigation.subscription.title'),
        description: t('navigation.subscription.description'),
        icon: <CreditCard />,
        href: Routes.SETTINGS_SUBSCRIPTION,
        hidden: !hasRoutePermissions(Routes.SETTINGS_SUBSCRIPTION)
      }, {
        label: t('navigation.organizations.title'),
        description: t('navigation.organizations.description'),
        icon: <LocationCity />,
        href: Routes.SETTINGS_ORGANIZATIONS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_ORGANIZATIONS)
      }, {
        label: t('navigation.textMessaging.title'),
        description: t('navigation.textMessaging.description'),
        icon: <Smartphone />,
        href: Routes.SETTINGS_TEXTING,
        hidden: !hasRoutePermissions(Routes.SETTINGS_TEXTING)
      }, {
        label: t('navigation.billingSettings.title'),
        description: t('navigation.billingSettings.description'),
        icon: <RequestQuote />,
        href: Routes.SETTINGS_BILLING_SETTINGS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_BILLING_SETTINGS)
      }, {
        label: t('navigation.taxes.title'),
        description: t('navigation.taxes.description'),
        icon: <AttachMoney />,
        href: Routes.SETTINGS_TAXES,
        hidden: !hasRoutePermissions(Routes.SETTINGS_TAXES)
      }, {
        label: t('navigation.pipelines.title'),
        icon: <LabelImportant />,
        href: Routes.SETTINGS_PIPELINES,
        hidden: !hasRoutePermissions(Routes.SETTINGS_PIPELINES)
      }, {
        label: t('navigation.spaces.title'),
        description: t('navigation.spaces.description'),
        icon: <MeetingRoom />,
        href: Routes.SETTINGS_SPACES,
        hidden: !hasRoutePermissions(Routes.SETTINGS_SPACES)
      }, {
        label: t('navigation.rooms.title'),
        description: t('navigation.rooms.description'),
        icon: <Hotel />,
        href: Routes.SETTINGS_ROOMS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_ROOMS)
      }, {
        label: t('navigation.menus.title'),
        description: t('navigation.menus.description'),
        icon: <Restaurant />,
        href: Routes.SETTINGS_MENUS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_MENUS)
      }, {
        label: t('navigation.packages.title'),
        description: t('navigation.packages.description'),
        icon: <Inventory />,
        href: Routes.SETTINGS_PACKAGES,
        hidden: !hasRoutePermissions(Routes.SETTINGS_PACKAGES)
      }, {
        label: t('navigation.budgets.title'),
        description: t('navigation.budgets.description'),
        icon: <Calculate />,
        href: Routes.SETTINGS_BUDGETS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_BUDGETS)
      }, {
        label: t('navigation.workflowTemplates.title'),
        icon: <AccountTree />,
        href: Routes.SETTINGS_WORKFLOW_TEMPLATES,
        hidden: !hasRoutePermissions(Routes.SETTINGS_WORKFLOW_TEMPLATES)
      }]
    }, {
      label: t('fields'),
      variant: 'header',
      showArrow: true,
      defaultExpanded,
      subItems: [{
        label: t('navigation.customFields.title'),
        description: t('navigation.customFields.description'),
        icon: <TextFields />,
        href: Routes.SETTINGS_CUSTOM_FIELDS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_CUSTOM_FIELDS)
      }, ...setupValueOptions.map(option => ({
        label: option.field_name,
        description: option.used_for.join(', '),
        href: `${Routes.SETTINGS_SETUP_VALUES}/${option.field_value}`,
        hidden: !hasRoutePermissions(Routes.SETTINGS_SETUP_VALUES),
        icon: option.icon
      }))]
    }, {
      label: t('navigation.tools.title'),
      variant: 'header',
      showArrow: true,
      subItems: [{
        label: t('navigation.tools.imports.title'),
        description: t('navigation.tools.imports.description'),
        icon: <ImportExport />,
        href: Routes.SETTINGS_IMPORTS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_IMPORTS)
      }, {
        label: t('navigation.tools.intakeForms.title'),
        description: t('navigation.tools.intakeForms.description'),
        icon: <DynamicForm />,
        href: Routes.SETTINGS_INTAKE_FORMS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_INTAKE_FORMS)
      }, {
        label: t('navigation.guestPortal.title'),
        icon: <Public />,
        href: Routes.SETTINGS_GUEST_PORTAL,
        hidden: !hasRoutePermissions(Routes.SETTINGS_GUEST_PORTAL)
      }, {
        label: t('navigation.tools.roomPickups.title'),
        description: t('navigation.tools.roomPickups.description'),
        icon: <RoomService />,
        href: Routes.SETTINGS_ROOM_PICKUPS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_ROOM_PICKUPS)
      }]
    }, {
      label: t('navigation.templates.title'),
      variant: 'header',
      showArrow: true,
      defaultExpanded: Boolean(search),
      subItems: [{
        label: t('navigation.templates.emailTemplates.title'),
        description: t('navigation.templates.emailTemplates.description'),
        href: Routes.SETTINGS_EMAIL_TEMPLATES,
        icon: <Mail />,
        hidden: !hasRoutePermissions(Routes.SETTINGS_EMAIL_TEMPLATES)
      }, {
        label: t('navigation.templates.textTemplates.title'),
        description: t('navigation.templates.textTemplates.description'),
        href: Routes.SETTINGS_TEXT_TEMPLATES,
        icon: <Smartphone />,
        hidden: !hasRoutePermissions(Routes.SETTINGS_TEXT_TEMPLATES)
      }, {
        label: t('navigation.templates.automationSequences.title'),
        description: t('navigation.templates.automationSequences.description'),
        href: Routes.SETTINGS_AUTOMATION_SEQUENCES,
        icon: <AutoAwesomeMotion />,
        hidden: !hasRoutePermissions(Routes.SETTINGS_AUTOMATION_SEQUENCES)
      }, {
        label: t('navigation.templates.documentTemplates.title'),
        description: t('navigation.templates.documentTemplates.description'),
        href: Routes.SETTINGS_DOCUMENT_TEMPLATES,
        icon: <InsertDriveFile />,
        hidden: !canUseOrgFeature(OrganizationFeatures.DOCUMENTS)
      }, {
        label: t('navigation.templates.proposalTemplates.title'),
        href: Routes.SETTINGS_PROPOSAL_TEMPLATES,
        icon: <InsertDriveFile />,
        hidden: !hasRoutePermissions(Routes.SETTINGS_PROPOSAL_TEMPLATES)
      }, {
        label: t('navigation.templates.files.title'),
        description: t('navigation.templates.files.description'),
        icon: <PermMedia />,
        href: Routes.SETTINGS_FILES,
        hidden: !hasRoutePermissions(Routes.SETTINGS_FILES)
      }]
    }, {
      label: t('navigation.developer.title'),
      variant: 'header',
      showArrow: true,
      defaultExpanded,
      subItems: [{
        label: t('navigation.developer.api.title'),
        description: t('navigation.developer.api.description'),
        icon: <Api />,
        href: Routes.SETTINGS_API_INTEGRATIONS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_API_INTEGRATIONS)
      }, {
        label: t('navigation.developer.apps.title'),
        description: t('navigation.developer.apps.description'),
        icon: <DeveloperBoard />,
        href: Routes.SETTINGS_OAUTH2_APPLICATIONS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_OAUTH2_APPLICATIONS)
      }]
    }, {
      label: t('navigation.integrations.title'),
      variant: 'header',
      showArrow: true,
      defaultExpanded,
      subItems: [{
        label: t('navigation.integrations.marketplace.title'),
        description: t('navigation.integrations.marketplace.description'),
        icon: <Apps />,
        href: Routes.SETTINGS_MARKETPLACE,
        hidden: !hasRoutePermissions(Routes.SETTINGS_MARKETPLACE)
      }, {
        label: t('navigation.integrations.onlinePayment.title'),
        description: t('navigation.integrations.onlinePayment.description'),
        icon: <CreditCard />,
        href: Routes.SETTINGS_STRIPE_INTEGRATIONS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_STRIPE_INTEGRATIONS)
      }, {
        label: t('navigation.integrations.calendar.title'),
        description: t('navigation.integrations.calendar.description'),
        icon: <EventAvailable />,
        href: Routes.SETTINGS_CALENDAR_INTEGRATIONS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_CALENDAR_INTEGRATIONS)
      }, {
        label: t('navigation.integrations.pms.title'),
        description: t('navigation.integrations.pms.description'),
        icon: <Adjust />,
        href: Routes.SETTINGS_PMS_INTEGRATIONS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_PMS_INTEGRATIONS)
      }, {
        label: t('navigation.integrations.cvent.title'),
        description: t('navigation.integrations.cvent.description'),
        icon: <Apps />,
        href: Routes.SETTINGS_CVENT_INTEGRATIONS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_CVENT_INTEGRATIONS)
      }, {
        label: t('navigation.integrations.webhooks.title'),
        description: t('navigation.integrations.webhooks.description'),
        icon: <Bolt />,
        href: Routes.SETTINGS_WEBHOOKS_INTEGRATIONS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_WEBHOOKS_INTEGRATIONS)
      }, {
        label: t('navigation.integrations.zapier.title'),
        description: t('navigation.integrations.zapier.description'),
        icon: <Link />,
        href: Routes.SETTINGS_ZAPIER_INTEGRATIONS,
        hidden: !hasRoutePermissions(Routes.SETTINGS_ZAPIER_INTEGRATIONS)
      }]
    }];
  }, [search, returnToAppUrl, t, hasRoutePermissions, selectedOrganization, setupValueOptions, canUseOrgFeature, canViewBookingPage]);
  const filteredItems = useMemo(() => items.map(item => {
    if (item.subItems) {
      // First check if the label of the category matches the search.
      if (item.label?.toLowerCase().includes(search.toLowerCase())) return item;

      // If the category label doesn't match, check if any of the sub items match the search.
      return {
        ...item,
        subItems: item.subItems.filter(subItem => subItem?.label?.toLowerCase().includes(search.toLowerCase()))
      };
    }
    return item;
  }).filter(item => item.subItems?.length || item.component ||
  //  Don't filter out components
  item.label?.toLowerCase().includes(search.toLowerCase())), [items, search]);
  return filteredItems;
};
export default useSettingSideNavItems;