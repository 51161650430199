import type { AutocompleteValue, FilterOptionsState } from '@mui/material'

import type { ValueLabelPair } from '@repo/et-types'

const defaultNone: ValueLabelPair = { label: 'None', value: null }
const newId = 'new'

export const getCurrentValue = <T extends ValueLabelPair>(
  value: AutocompleteValue<string | number, boolean, boolean, boolean>,
  options: T[],
  multiple: boolean
) => {
  if (multiple) {
    const newValue: T[] = ((value || []) as string[]).map((val) => {
      const option = options.find((op) => String(op.value) === String(val))

      if (option) return option as T
      else return { value: val, label: val } as T
    })

    return newValue
  } else {
    const option = options.find((op) => op.value === value)

    return option
  }
}

export const getSingleOptions = <T extends ValueLabelPair>(
  values: T[],
  defaultValue: T | T[],
  shouldShowDefaultValueOptions: boolean,
  shouldShowNone: boolean
) => {
  const finalOptions = [...values]

  if (shouldShowDefaultValueOptions) {
    if (
      defaultValue &&
      !Array.isArray(defaultValue) &&
      !finalOptions.find((op) => op.value === defaultValue?.value)
    ) {
      finalOptions.unshift(defaultValue)
    }
  }

  if (shouldShowNone) finalOptions.unshift(defaultNone as T)

  return finalOptions
}

export const getMultipleOptions = <T extends ValueLabelPair>(
  values: T[],
  defaultValue: T | T[],
  shouldShowDefaultValueOptions: boolean,
  shouldShowNone: boolean
) => {
  const finalOptions = [...values]

  if (shouldShowDefaultValueOptions) {
    if (defaultValue && Array.isArray(defaultValue)) {
      defaultValue.forEach((item) => {
        if (!finalOptions.find((op) => op.value === item?.value) && item) {
          finalOptions.unshift(item)
        }
      })
    }
  }

  if (shouldShowNone) finalOptions.unshift(defaultNone as T)

  return finalOptions
}

export const optionsFilterer = <T extends ValueLabelPair>(
  options: T[],
  state: FilterOptionsState<T>,
  canCreateNew: boolean,
  createNewMessage: string,
  onSearchChange: (value: string) => void
): T[] => {
  const inputValue = state.inputValue
  const finalOptions = [...options]

  if (canCreateNew && inputValue.length > 0) {
    finalOptions.unshift({
      label: `${createNewMessage || 'Create:'} ${inputValue}`,
      inputValue: inputValue,
      value: newId
    } as T)
  }

  // if onSearchChange is defined, it means that the parent component is handling filtering
  if (onSearchChange) {
    return finalOptions
  } else {
    return finalOptions.filter((option) => {
      if (!inputValue) return true

      const label = option.label.toLowerCase()
      const optionValue = String(option.value)?.toLowerCase()

      return (
        label.includes(inputValue.toLowerCase()) || optionValue?.includes(inputValue.toLowerCase())
      )
    })
  }
}

export const getReadOnlyValues = <T extends ValueLabelPair>(autocompleteValue) => {
  if (Array.isArray(autocompleteValue)) {
    const vals = autocompleteValue?.map((val) => {
      const isString = typeof val === 'string'
      const isNumber = typeof val === 'number'

      return isString || isNumber ? val : val?.label
    })

    return vals
  } else {
    return (autocompleteValue as T)?.label
  }
}
