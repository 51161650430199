import axios from 'axios'

import { format } from 'date-fns'
import Qs from 'qs'

import useChainContextStore from 'store/chainContext'
import clearLocalStorageKeys from 'utils/clearLocalStorageKeys'
import { ET_JWT_LOCAL_STORAGE_KEY, ET_REVIEW_APP_API_URL_KEY } from 'utils/constants/global'

import dataFormatter from 'utils/dataFormatter'

import getLoginUrl from 'utils/getLoginUrl'

import type { AxiosError, AxiosRequestHeaders } from 'axios'

// Override toJSON method to keep the date in ISO format. When the toJSON method
// is called on dates in a GMT+(N) timezone, the JSON.stringify method is
// converting the date to UTC. This is a workaround to keep the timezone
// information & pass through the desired date.
Date.prototype.toJSON = function () {
  // Keep in ISO format and preserve timezone (format: 'yyyy-MM-ddTHH:mm:ss.sssZ')
  return format(this, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
}

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL
})

api.defaults.withCredentials = true
api.defaults.withXSRFToken = true

api.interceptors.response.use(
  (response) => {
    try {
      const deserializeData = dataFormatter.deserialize(response.data)

      response.data.normalizedData = deserializeData
    } catch {
      // do nothing
    }

    return response
  },
  (error: AxiosError) => {
    // Intercept 401 errors with our API and redirect to login page.
    if (
      error.response?.status === 401 &&
      error.config?.baseURL === process.env.NEXT_PUBLIC_API_URL
    ) {
      clearLocalStorageKeys()

      window.location.href = getLoginUrl(window.location.href)
    }

    return Promise.reject(error)
  }
)

api.interceptors.request.use((config) => {
  config.paramsSerializer = (params) =>
    Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    })

  config.headers = {
    'X-Internal-Api': true,
    'X-API-ORG': useChainContextStore.getState().selectedOrganization?.subdomain,
    ...config.headers
  } as unknown as AxiosRequestHeaders

  if (process.env.NEXT_PUBLIC_AUTH_MODE == 'JWT') {
    const jwt = localStorage.getItem(ET_JWT_LOCAL_STORAGE_KEY)

    const reviewApiUrl = localStorage.getItem(ET_REVIEW_APP_API_URL_KEY)

    if (jwt) {
      config.headers = {
        ...config.headers,
        Authorization: `JWT ${jwt}`
      } as AxiosRequestHeaders
    }

    if (reviewApiUrl) {
      config.baseURL = reviewApiUrl
    }
  }

  return config
})

export default api
