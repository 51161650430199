import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'

import type { Ref } from 'react'

import { Box, Paper, Stack } from '@mui/material'

import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_REORDER_COL_DEF,
  DataGridPro as MUIDataGrid,
  useGridApiRef
} from '@mui/x-data-grid-pro'

import { useDebounceCallback, useLocalStorage } from 'usehooks-ts'

import DataGridBatchActions from 'components/common/DataGrid/DataGridBatchActions'

import { ACTIONS_COLUMN_ID, DEFAULT_PAGINATION_MODEL } from 'hooks/dataGrid/constants'
import useDataGridColumns from 'hooks/dataGrid/useDataGridColumns'

import type { DataGridMethods, DataGridProps } from 'components/common/DataGrid/DataGrid.types'

import type {
  GridColumnResizeParams,
  GridPaginationModel,
  GridProSlotsComponent,
  GridRowModel,
  GridRowOrderChangeParams,
  GridRowSelectionModel
} from '@mui/x-data-grid-pro'
import type { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps'
import type { Base } from '@repo/et-types'

const updateRowPosition = (initialIndex: number, newIndex: number, rows: Array<GridRowModel>) => {
  const rowsClone = [...rows]
  const row = rowsClone.splice(initialIndex, 1)[0]

  rowsClone.splice(newIndex, 0, row)
  return rowsClone
}

const defaultRowCount = Number.MAX_VALUE
const defaultData = []
const defaultPageSizeOptions = [25]
const defaultInitialState = {}
const defaultShowInlineActions = true
const defaultShowPagination = false
const defaultNoWrapper = false
const defaultMinHeight = '300px'

const DataGrid = forwardRef(
  <T extends Base>(
    {
      actions,
      actionsDropdownProps,
      batchActions,
      batchActionsDropdownProps,
      columnProperties,
      columnPersistenceKey,
      containerProps,
      customActionsColumn,
      customReorderColumn,
      data = defaultData,
      getReorderLabel,
      infinite,
      initialPagination,
      initialState = defaultInitialState,
      inlineActions = defaultShowInlineActions,
      loading,
      loadMoreElement,
      minHeight = defaultMinHeight,
      noMoreElement,
      noRowsElement,
      noWrapper = defaultNoWrapper,
      onAllRowsSelect,
      onSelectAllRecords,
      onPageChange,
      onPageSizeChange,
      onReorder,
      onRowSelect,
      pageSizeOptions = defaultPageSizeOptions,
      pagination = defaultShowPagination,
      initialSelectedRows = defaultData,
      slots,
      shouldLoadMore,
      sx,
      ...props
    }: DataGridProps<T>,
    ref: Ref<DataGridMethods>
  ) => {
    const apiRef = useGridApiRef()

    const [columnSizes, setColumnSizes] = useLocalStorage(
      columnPersistenceKey ? `${columnPersistenceKey}_columnSizes` : undefined,
      {}
    )

    const [paginationModel, setPaginationModel] = useState(
      initialPagination ?? DEFAULT_PAGINATION_MODEL
    )

    const [rowSelectionModel, setRowSelectionModel] =
      useState<GridRowSelectionModel>(initialSelectedRows)

    const [selectAllRecords, setSelectAllRecords] = useState(false)

    const columns = useDataGridColumns({
      actions,
      actionsDropdownProps,
      columnProperties,
      columnSizes,
      customActionsColumn,
      customReorderColumn,
      inlineActions
    })

    // Handles the pagination model change.
    const handlePaginationModelChange = useCallback(
      (newModel: GridPaginationModel) => {
        const isNextPage = newModel?.page > paginationModel?.page
        const newPage = !shouldLoadMore && isNextPage ? paginationModel.page : newModel.page

        if (newModel.pageSize !== paginationModel.pageSize) onPageSizeChange?.(newModel)

        if (newModel.page !== paginationModel.page) onPageChange?.({ ...newModel, page: newPage })

        return setPaginationModel({ page: newPage, pageSize: newModel.pageSize })
      },
      [
        onPageChange,
        onPageSizeChange,
        paginationModel?.page,
        paginationModel?.pageSize,
        shouldLoadMore
      ]
    )

    // Handles the row selection model change.
    const handleRowSelection = useCallback(
      (newRowSelectionModel: GridRowSelectionModel) => {
        onRowSelect?.(newRowSelectionModel)

        if (newRowSelectionModel?.length === data?.length) {
          onAllRowsSelect?.(newRowSelectionModel)
        }

        onSelectAllRecords?.(false)
        setSelectAllRecords(false)

        return setRowSelectionModel(newRowSelectionModel)
      },
      [data?.length, onAllRowsSelect, onRowSelect, onSelectAllRecords]
    )

    const handleRowReorder = useCallback(
      async (params: GridRowOrderChangeParams) => {
        const newRows = updateRowPosition(params.oldIndex, params.targetIndex, data)

        onReorder?.(newRows as T[])

        return newRows
      },
      [data, onReorder]
    )

    // Elements to be displayed in the footer of the grid
    const footerElement = useMemo(() => {
      if (shouldLoadMore && loadMoreElement && data?.length > 0) return loadMoreElement

      if (noMoreElement && !shouldLoadMore) return noMoreElement

      return undefined
    }, [loadMoreElement, noMoreElement, shouldLoadMore, data?.length])

    // Props for infinite loading version of the grid
    const infiniteLoadingProps = useMemo(
      () => ({
        hideFooterPagination: true,
        onRowsScrollEnd: () =>
          handlePaginationModelChange({ ...paginationModel, page: paginationModel.page + 1 })
      }),
      [handlePaginationModelChange, paginationModel]
    )

    // Props to support reordering rows
    const reorderProps = useMemo(
      () => ({ rowReordering: true, onRowOrderChange: handleRowReorder }),
      [handleRowReorder]
    )

    // Props for row selection
    const selectionProps = useMemo(
      () => ({
        checkboxSelection: true,
        onRowSelectionModelChange: handleRowSelection,
        rowSelectionModel
      }),
      [handleRowSelection, rowSelectionModel]
    )

    const defaultLeftPinnedColumns = useMemo(() => {
      const cols = onReorder ? [GRID_REORDER_COL_DEF.field] : []

      if (onRowSelect || onAllRowsSelect) {
        cols.push(GRID_CHECKBOX_SELECTION_COL_DEF.field)
      }

      return cols
    }, [onRowSelect, onAllRowsSelect, onReorder])

    const defaultRightPinnedColumns = useMemo(
      () => (actions?.length > 0 || customActionsColumn ? [ACTIONS_COLUMN_ID] : []),
      [actions, customActionsColumn]
    )

    // Initial state for the grid
    const initialGridState = useMemo(
      () => ({
        pinnedColumns: {
          left: initialState?.pinnedColumns?.left
            ? [...defaultLeftPinnedColumns, ...initialState.pinnedColumns.left]
            : defaultLeftPinnedColumns,
          right: initialState?.pinnedColumns?.right
            ? [...defaultRightPinnedColumns, ...initialState.pinnedColumns.right]
            : defaultRightPinnedColumns
        },
        paginationModel: {
          page: paginationModel?.page,
          pageSize: paginationModel?.pageSize
        },
        ...(initialState ?? {})
      }),
      [
        initialState,
        paginationModel?.page,
        paginationModel?.pageSize,
        defaultLeftPinnedColumns,
        defaultRightPinnedColumns
      ]
    )

    // Props for the slots
    const slotProps = useMemo<GridProSlotProps>(
      () => ({
        pagination: {
          nextIconButtonProps: { disabled: !shouldLoadMore },
          backIconButtonProps: { disabled: paginationModel?.page <= 1 },
          ...props?.slotProps?.pagination
        },
        loadingOverlay: { variant: 'skeleton', noRowsVariant: 'skeleton' },
        ...props?.slotProps
      }),
      [paginationModel, shouldLoadMore, props?.slotProps]
    )

    const finalSlots = useMemo<Partial<GridProSlotsComponent>>(
      () => ({
        footer: footerElement,
        noRowsOverlay: noRowsElement ? noRowsElement : undefined,
        noResultsOverlay: noRowsElement ? noRowsElement : undefined,
        ...slots
      }),
      [footerElement, noRowsElement, slots]
    )

    // Custom styles for the grid
    const sxProps = useMemo(
      () => ({
        '.MuiDataGrid-rowCount': { display: 'none' }, // Hides the row count when theres pagination = true
        '.MuiTablePagination-displayedRows': { display: 'none' }, // Hides the row count when theres pagination = false
        '--DataGrid-overlayHeight': minHeight, // Set the minimum height for the overlay
        minHeight, // Set the minimum height for the grid
        ...sx
      }),
      [sx, minHeight]
    )

    // Expose the methods to the parent component
    useImperativeHandle(
      ref,
      () => ({
        changePageSize: (pageSize: number) =>
          handlePaginationModelChange({ ...paginationModel, pageSize }),
        getPaginationModel: () => paginationModel,
        getSelectedRows: () => rowSelectionModel,
        nextPage: () =>
          handlePaginationModelChange({ ...paginationModel, page: paginationModel.page + 1 }),
        prevPage: () =>
          handlePaginationModelChange({ ...paginationModel, page: paginationModel.page - 1 }),
        setSelectedRows: (rows: GridRowSelectionModel) => handleRowSelection(rows)
      }),
      [handlePaginationModelChange, paginationModel, rowSelectionModel, handleRowSelection]
    )

    const shouldHideFooter = useMemo(() => {
      if (infinite) return !noMoreElement

      if (pagination || loadMoreElement || slots?.footer) return false

      return true
    }, [infinite, pagination, noMoreElement, slots, loadMoreElement])

    const rows = useMemo(
      () =>
        getReorderLabel ? data.map((row) => ({ ...row, __reorder__: getReorderLabel(row) })) : data,
      [data, getReorderLabel]
    )

    const handleColumnResize = useCallback(
      (params: GridColumnResizeParams) => {
        if (!columnPersistenceKey) return

        return setColumnSizes((prev) => ({ ...prev, [params.colDef.field]: params.width }))
      },
      [columnPersistenceKey, setColumnSizes]
    )
    const debouncedHandleColumnResize = useDebounceCallback(handleColumnResize, 500)

    const finalPageSizeOptions = pageSizeOptions ?? []
    const dataGridDefaultProps = {
      ...(onRowSelect || onAllRowsSelect ? selectionProps : {}),
      ...(infinite ? infiniteLoadingProps : {}),
      ...(onReorder ? reorderProps : {})
    }
    const wrapperComponent = noWrapper ? 'div' : Paper

    const handleClearAllClick = useCallback(() => {
      setSelectAllRecords(false)
      onSelectAllRecords?.(false)
    }, [onSelectAllRecords])

    const handleSelectAllClick = useCallback(() => {
      setSelectAllRecords(true)
      onSelectAllRecords?.(true)
    }, [onSelectAllRecords])

    return (
      <Stack position="relative" height="100%">
        <DataGridBatchActions
          batchActions={batchActions}
          batchActionsDropdownProps={batchActionsDropdownProps}
          onSelectAllRecords={handleSelectAllClick}
          onClearAllRecords={handleClearAllClick}
          allRecordsSelected={selectAllRecords}
          rowSelectionModel={rowSelectionModel}
        />
        <Box
          component={wrapperComponent}
          display="flex"
          flexDirection="column"
          flex={1}
          borderRadius={4}
          {...containerProps}>
          <MUIDataGrid
            apiRef={apiRef}
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
            disableColumnSelector
            disableMultipleColumnsSorting
            initialState={initialGridState}
            loading={loading}
            pagination={pagination}
            paginationModel={paginationModel}
            pageSizeOptions={finalPageSizeOptions}
            slotProps={slotProps}
            slots={finalSlots}
            sx={sxProps}
            hideFooter={shouldHideFooter}
            {...props}
            {...dataGridDefaultProps}
            onColumnResize={debouncedHandleColumnResize}
            onPaginationModelChange={handlePaginationModelChange}
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            rowCount={defaultRowCount}
          />
        </Box>
      </Stack>
    )
  }
)

DataGrid.displayName = 'DataGrid'

export default DataGrid as <T extends Base>(
  props: DataGridProps<T> & { ref?: Ref<DataGridMethods> }
) => React.JSX.Element
