import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Drawer, List, Stack } from '@mui/material';
import { usePathname } from 'next/navigation';
import SideNavHeader from 'components/layout/SideNav/SideNavHeader';
import SideNavItem from 'components/layout/SideNav/SideNavItem';
import SideNavPopper from 'components/layout/SideNav/SideNavPopper';
import useSideNav from 'hooks/common/layout/sideNav/useSideNav';
import useResponsive from 'hooks/useResponsive';
import { SIDE_NAV } from 'utils/constants/layout';
import type { Theme } from '@mui/material';
import type { SideNavItem as SideNavItemType } from 'hooks/common/layout/sideNav/useSideNav';
import type { MouseEvent } from 'react';

// Using transform to animate the side nav. Transform is used instead of since
// it is more performant.
const stackStyles = (mini: boolean) => ({
  transform: mini ? 'scaleX(.33)' : 'none',
  height: 1,
  backgroundColor: (theme: Theme) => theme.palette.grey[800],
  color: (theme: Theme) => theme.palette.grey[400],
  overflow: 'auto',
  position: 'fixed',
  transformOrigin: 'left',
  width: SIDE_NAV.W_VERTICAL
});
const drawerStyles = {
  sx: {
    width: SIDE_NAV.W_VERTICAL,
    backgroundColor: (theme: Theme) => theme.palette.grey[800],
    color: (theme: Theme) => theme.palette.grey[400]
  }
};
type SideNavProps = {
  open: boolean;
  onClose: () => void;
  mini: boolean;
  toggleSideNavMini: () => void;
};
const SideNav = ({
  open,
  onClose,
  mini,
  toggleSideNavMini
}: SideNavProps) => {
  const pathname = usePathname();
  const [hoveredElem, setHoveredElem] = useState<{
    currentTarget: HTMLElement | null;
    items: SideNavItemType[];
  }>({
    currentTarget: null,
    items: []
  });
  const lgUp = useResponsive('up', 'lg');
  const {
    items,
    isSettingNav
  } = useSideNav();
  const handleItemHover = useCallback((e: MouseEvent<HTMLLIElement> | null, hoverItems: SideNavItemType[]) => setHoveredElem({
    currentTarget: e?.currentTarget || null,
    items: hoverItems || []
  }), []);
  const onItemHover = useMemo(() => {
    if (mini) return handleItemHover;
    return undefined;
  }, [mini, handleItemHover]);
  const showSideNavToggle = !isSettingNav || mini;
  const listPadding = useMemo(() => ({
    p: mini ? 1 : 2
  }), [mini]);
  const mainStackStyle = stackStyles(mini);
  const onElementHover = useCallback(() => setHoveredElem(hoveredElem), [hoveredElem]);
  const onElementLeave = useCallback(() => setHoveredElem({
    currentTarget: null,
    items: []
  }), []);
  const sideNavItems = useMemo(() => items.map((item, index) => <SideNavItem key={index} mini={mini} onItemHover={onItemHover} {...item} />), [items, mini, onItemHover]);
  const renderContent = useCallback(() => <>
        <SideNavHeader mini={mini} onClose={onClose} showSideNavToggle={showSideNavToggle} toggleSideNavMini={toggleSideNavMini} />
        <List component="nav" aria-labelledby="side-nav-header" sx={listPadding}>
          {sideNavItems}
        </List>
      </>, [mini, onClose, showSideNavToggle, toggleSideNavMini, sideNavItems, listPadding]);
  const renderPopper = useCallback(() => mini ? <SideNavPopper anchorEl={hoveredElem.currentTarget} items={hoveredElem.items} onMouseEnter={onElementHover} onMouseLeave={onElementLeave} /> : null, [hoveredElem, mini, onElementHover, onElementLeave]);
  useEffect(() => {
    if (open && !lgUp) onClose?.();
    // Only run this effect when the pathname changes, this is to ensure that
    // the side nav closes when the user navigates to a different page,
    // regardless of how they do so. If the side nav is open in mobile but the
    // URL changes to another, internal route, we still want this to change
    // because to the user thats a navigation event.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  if (lgUp) {
    return <>
        <Stack sx={mainStackStyle}>{renderContent()}</Stack>
        {renderPopper()}
      </>;
  }
  return <Drawer open={open} onClose={onClose} PaperProps={drawerStyles} data-sentry-element="Drawer" data-sentry-component="SideNav" data-sentry-source-file="SideNav.tsx">
      {renderContent()}
    </Drawer>;
};
export default memo(SideNav);