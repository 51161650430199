import React, { useCallback, useMemo } from 'react'

import { Button, Grid2 as Grid, Paper } from '@mui/material'

import { DIALOG_TYPES } from 'types/dialog'
import { defaultDiscardLabel, defaultSubmitLabel } from 'utils/forms'

import type { DialogFormFooterProps } from 'components/forms/DialogForm/DialogForm.types'

const defaultVariant = DIALOG_TYPES.DRAWER

/**
 * The footer component for a form in a dialog. Current dialogs supported are:
 * Drawer & Modal.
 * @component
 */
const DialogFormFooter = ({
  discardButtonProps,
  isLoading,
  onDiscard,
  onSubmit,
  reverseButtons,
  variant = defaultVariant,
  submitButtonProps
}: DialogFormFooterProps) => {
  const isDrawer = variant === defaultVariant
  const footerVariant = isDrawer ? 'outlined' : 'elevation'
  const submitButtonLabel = submitButtonProps?.label || defaultSubmitLabel
  const discardButtonLabel = discardButtonProps?.label || defaultDiscardLabel

  const padding = useMemo(() => {
    if (isDrawer) return { px: 4, py: 2 }

    return { px: 2, py: 2 }
  }, [isDrawer])

  const footerStyles = useCallback(
    (theme) => ({
      bgcolor: theme.palette.background.paper,
      position: 'sticky',
      bottom: 0,
      left: 0,
      zIndex: 1202, // setting to 1202 to be above all other elements in the dialog since html editor is 1201 and Mui drawer is 1200
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: reverseButtons ? 'row-reverse' : 'row',
      ...padding
    }),
    [padding, reverseButtons]
  )

  const renderDiscardButton = useCallback(() => {
    if (!onDiscard) return null

    return (
      <Grid>
        <Button variant="outlined" onClick={onDiscard} {...discardButtonProps}>
          {discardButtonLabel}
        </Button>
      </Grid>
    )
  }, [discardButtonLabel, discardButtonProps, onDiscard])

  const renderSubmitButton = useCallback(() => {
    if (!onSubmit) return null

    return (
      <Grid>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          loading={isLoading}
          {...submitButtonProps}>
          {submitButtonLabel}
        </Button>
      </Grid>
    )
  }, [isLoading, onSubmit, submitButtonLabel, submitButtonProps])

  return (
    <Grid
      container
      square
      component={Paper}
      variant={footerVariant}
      sx={footerStyles}
      data-testid="DialogFormFooter"
      columnSpacing={2}>
      {renderDiscardButton()}
      {renderSubmitButton()}
    </Grid>
  )
}

export default DialogFormFooter
