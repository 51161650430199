import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type MainLayoutState = {
  sideNavOpen: boolean
  sideNavMini: boolean
  toggleSideNav: () => void
  setSideNavMini: (value: boolean) => void
}

const useMainLayoutStore = create(
  persist<MainLayoutState>(
    (set) => ({
      sideNavOpen: false,
      sideNavMini: false,
      toggleSideNav: () => set((state) => ({ sideNavOpen: !state.sideNavOpen })),
      setSideNavMini: (value: boolean) => set(() => ({ sideNavMini: value }))
    }),
    {
      name: 'main-layout-store',
      partialize: (state) =>
        ({ sideNavOpen: state.sideNavOpen, sideNavMini: state.sideNavMini }) as MainLayoutState
    }
  )
)

export default useMainLayoutStore
