import { Campaign } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslations } from 'next-intl';
import { CannyChangelog } from 'react-canny';
const renderCannyComponent = () => null;
const ProductUpdate = () => {
  const t = useTranslations();
  return <>
      <Tooltip title={t('productUpdates')} placement="bottom" arrow data-sentry-element="Tooltip" data-sentry-source-file="ProductUpdate.tsx">
        <IconButton data-canny-changelog data-sentry-element="IconButton" data-sentry-source-file="ProductUpdate.tsx">
          <Campaign data-sentry-element="Campaign" data-sentry-source-file="ProductUpdate.tsx" />
        </IconButton>
      </Tooltip>
      <CannyChangelog position="bottom" align="right" component={renderCannyComponent} data-sentry-element="CannyChangelog" data-sentry-source-file="ProductUpdate.tsx" />
    </>;
};
export default ProductUpdate;