import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'

import type { Ref } from 'react'

import { Box, Button, Grid2 as Grid, Paper, Step, StepLabel, Stepper } from '@mui/material'

import { GRID_ROW_SPACING, REACT_STICKY_INDEX } from 'theme/constants'

import type {
  HorizontalStepperProps,
  StepperMethods
} from 'components/common/layout/HorizontalStepper'

const stepComponentWrapperStyle = { maxWidth: '100%' }

const HorizontalStepper = forwardRef(
  (
    {
      backBtnLabel,
      discardBtnLabel,
      initialStep = 0,
      nextBtnLabel,
      nextButtonDisabled,
      onDiscard,
      onStepChange,
      onSubmit,
      stepsConfig,
      submitBtnLabel,
      submitBtnProps,
      backBtnProps,
      nextBtnProps,
      discardBtnProps,
      stepperProps,
      ...others
    }: HorizontalStepperProps,
    ref: Ref<StepperMethods>
  ) => {
    const [activeStep, setActiveStep] = useState<number>(initialStep)

    const isLastStep = activeStep === stepsConfig?.length - 1 || false
    const isFirstStep = activeStep === 0 || false

    const backText = backBtnLabel || 'Back'
    const nextText = nextBtnLabel || 'Next'
    const submitText = submitBtnLabel || 'Submit'
    const discardText = discardBtnLabel || 'Discard'

    const handleNextClick = useCallback(() => setActiveStep(activeStep + 1), [activeStep])

    const handleBackClick = useCallback(() => setActiveStep(activeStep - 1), [activeStep])

    useEffect(() => {
      onStepChange?.(activeStep)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep])

    useImperativeHandle(
      ref,
      () => ({
        getActiveStep: () => activeStep,
        setActiveStep: (step: number) => setActiveStep(step)
      }),
      [setActiveStep, activeStep]
    )

    if (!stepsConfig) return null

    return (
      <Grid container rowGap={GRID_ROW_SPACING} direction="column" {...others}>
        <Grid>
          <Stepper activeStep={activeStep} {...stepperProps}>
            {stepsConfig.map((item, index) => (
              <Step key={index}>
                <StepLabel>{item.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid flex="1" style={stepComponentWrapperStyle}>
          {stepsConfig[activeStep].component}
        </Grid>
        <Grid
          component={Paper}
          display="flex"
          justifyContent="space-between"
          position="sticky"
          bottom={0}
          elevation={0}
          p="1.25rem 0"
          zIndex={REACT_STICKY_INDEX}>
          <Button variant="outlined" onClick={onDiscard} {...discardBtnProps}>
            {discardText}
          </Button>
          <Box>
            <Button
              variant="text"
              onClick={handleBackClick}
              disabled={isFirstStep}
              {...backBtnProps}>
              {backText}
            </Button>
            {!isLastStep && (
              <Button
                variant="contained"
                disabled={nextButtonDisabled}
                onClick={handleNextClick}
                color="primary"
                {...nextBtnProps}>
                {nextText}
              </Button>
            )}
            {isLastStep && (
              <Button variant="contained" color="primary" onClick={onSubmit} {...submitBtnProps}>
                {submitText}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    )
  }
)

HorizontalStepper.displayName = 'HorizontalStepper'

export default HorizontalStepper as (
  props: HorizontalStepperProps & { ref?: Ref<StepperMethods> }
) => React.JSX.Element
