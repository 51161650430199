import { ExitToApp } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
import { useTranslations } from 'next-intl';
import useLegacyAppUrl from 'hooks/useLegacyAppUrl';
import useScreenSizes from 'hooks/useScreenSizes';
const BackToClassicButton = () => {
  const t = useTranslations();
  const {
    isSmallScreen
  } = useScreenSizes();
  const legacyAppUrl = useLegacyAppUrl();
  const url = `${legacyAppUrl}${legacyAppUrl.includes('?') ? '&' : '?'}force_classic=true`;
  if (isSmallScreen) {
    return <Tooltip title={t('backToClassic')}>
        <IconButton size="small" component="a" href={url}>
          <ExitToApp sx={{
          rotate: '180deg'
        }} />
        </IconButton>
      </Tooltip>;
  }
  return <Button href={url} variant="soft" startIcon={<ExitToApp sx={{
    rotate: '180deg'
  }} />} data-sentry-element="Button" data-sentry-component="BackToClassicButton" data-sentry-source-file="BackToClassicButton.tsx">
      {t('backToClassic')}
    </Button>;
};
export default BackToClassicButton;